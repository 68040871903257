body {
    font-family: $base-font;
    font-size: 1.125rem;
    line-height: 1.4;
    color: $color-black;
    @include breakpoint(large) {
        font-size: 1.5rem;
    }
}
// Font sizes
h1,
.h1 {
    font-size: 4rem;
    line-height: 0.8;
    margin-top: 3.8125rem;
    @include breakpoint(large) {
        font-size: 7rem;
    }
}

h2,
.h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    margin-top: 2.9375rem;
    line-height: 43.2px;

    @include breakpoint(large) {
        font-size: 5.5rem;
        line-height: 70.4px;
    }
}

h3,
.h3 {
    font-size: 2rem;
    line-height: 1;
    margin-top: 2.5rem;
}

h4,
.h4 {
    font-size: 1.5rem;
    line-height: 1.2;
}

h5,
.h5 {
    font-size: 1.25rem;
    line-height: 1.4;
    margin-top: 1.5rem;
}

h6,
.h6 {
    font-size: 1rem;
    line-height: 1.3125rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 700;
    font-family: 'Artz';
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
.rich-text span {
    scroll-margin-top: 10rem;
}

a {
    color: $color-black;
    text-decoration: none;
}

p a {
    text-decoration: underline;
}

hr {
    border: none;
    height: $width-border;
    background: $color-line;
    margin: 2rem -1rem;
    width: calc(100% + 2rem);
    @include breakpoint(large) {
        margin: 5rem -1rem;
    }

    &.content-width {
        max-width: $width-block;
    }
    .container--with-sidebar & {
        margin: 2rem 0;
        width: 100%;
    }
}

cite {
    font-style: normal;
    font-weight: bold;
}

ul {
    list-style-type: none;
}

ul li:before {
    content: '-';
    position: absolute;
    font-weight: 800;
    text-indent: -1rem;
}

ul li {
    padding: 0;
}

mark {
    background-color: $color-delete;
}

ins {
    color: $color-insert;
}

del {
    color: $color-delete;
}

small {
    font-size: 0.625rem;
}
blockquote {
    padding: 0.5rem;
    border-left: 0.5rem solid $color-primary;
}
.green {
    color: $color-secondary;
}
dl {
    display: flex;
    flex-flow: column;
    dt {
        font-weight: 700;
        display: inline-flex;
    }
    dd {
        margin-left: 0;
        display: inline-flex;
    }
}

;@import "sass-embedded-legacy-load-done:13";