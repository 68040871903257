.row {
    display: flex;
    flex-flow: row wrap;

    &--nowrap {
        flex-flow: row nowrap;
    }

    &--nowrap-medium {
        @include breakpoint(medium) {
            flex-flow: row nowrap;
        }
    }

    // Row alignment
    &--center {
        align-items: center;
    }

    &--bottom {
        align-items: flex-end;
    }

    &--top {
        align-items: flex-start;
    }

    &--baseline {
        align-items: baseline;
    }

    // Row justification
    &--justify-end {
        justify-content: flex-end;
    }

    &--justify-center {
        justify-content: center;
    }

    &--space-between {
        justify-content: space-between;
    }

    // Row distribution
    &.distribute {
        > * + * {
            margin-left: $space-gap;
        }
    }

    &.distribute-s {
        > * + * {
            margin-left: math.div($space-gap, 2);
        }
    }

    &.distribute-l {
        > * + * {
            margin-left: $space-gap * 2;

            @include breakpoint(medium) {
                margin-left: $space-gap * 0.25;
            }

            @include breakpoint(large) {
                margin-left: $space-gap * 2;
            }
        }
    }
}

.stack {
    display: flex;
    flex-flow: column nowrap;

    > * + * {
        margin-top: $space-gap;
    }

    &--tight {
        > * + * {
            margin-top: 0;
        }
    }

    &--loose {
        > * + * {
            margin-top: $space-gap * 2;
        }
    }

    &--lined {
        > * + * {
            border-top: $width-border solid $color-line;
        }
    }

    &--lined-primary {
        > * + * {
            border-top: $width-border solid $color-primary-darker;
        }
    }
    &--lined-grey {
        > * + * {
            border-top: $width-border solid $color-medium-grey;
        }
    }

    // Alignment
    &--start {
        align-items: flex-start;
    }

    &--end {
        align-items: flex-end;
    }

    &--center {
        align-items: center;
    }
    &--justify-center {
        justify-content: center;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $space-gap;

    &--two {
        @include breakpoint(large) {
            grid-template-columns: repeat(2, 1fr);

            &.grid--stack-left {
                grid-template-columns: auto calc(33% - #{$space-gap * 1.25});
            }

            &.grid--stack-right {
                grid-template-columns: calc(33% - #{$space-gap * 1.25}) auto;
            }
        }
    }

    &--three {
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);

            :last-child:nth-child(odd) {
                grid-column: span 2;
            }
        }

        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);

            :last-child:nth-child(odd) {
                grid-column: auto;
            }
        }
    }

    &--four {
        h1,
        h2,
        h3 {
            word-break: break-word;
        }
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);

            :last-child:nth-child(odd) {
                grid-column: span 2;
            }
        }

        @include breakpoint(enormous) {
            grid-template-columns: repeat(4, 1fr);

            :last-child:nth-child(odd) {
                grid-column: auto;
            }
        }
    }
    &--gap-2 {
        grid-gap: $space-gap;

        @include breakpoint(medium) {
            grid-gap: $space-gap * 2;
        }
    }
}

.cluster {
    > * {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: calc(#{$space-gap} / 2 * -1);
    }

    > * > * {
        margin: calc(#{$space-gap} / 2);
    }

    &--tight {
        > * {
            margin: calc(#{$space-gap} / 4 * -1);
        }

        > * > * {
            margin: calc(#{$space-gap} / 4);
        }
    }

    &--loose {
        > * {
            margin: $space-gap * -1;
        }

        > * > * {
            margin: $space-gap;
        }
    }

    &--align-center {
        > * {
            justify-content: center;
        }
    }

    &--space-evenly {
        > * {
            justify-content: flex-start;

            @include breakpoint(small) {
                justify-content: space-around;
            }
        }
    }
}

.box {
    padding: $space-gap;

    &--small {
        padding: math.div($space-gap, 2) $space-gap;
    }

    &--large {
        padding: $space-gap * 2 $space-gap;
    }
    &--extra-large {
        padding: $space-gap * 3 $space-gap * 2;
    }
}

.multicolumn {
    @include breakpoint(medium) {
        columns: 2;
        gap: $space-gap;

        > * {
            break-inside: avoid;
            margin-bottom: $space-gap;
        }
    }

    @include breakpoint(large) {
        columns: 3;
    }
}

;@import "sass-embedded-legacy-load-done:15";