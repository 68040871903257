// Base sizes
$base-font-size: 1rem !default;
$base-font:
    'Inter',
    -apple-system,
    'Segoe UI',
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    'Helvetica Neue',
    sans-serif;
$base-line-height: 1.5;

// Harmony ratio
$harmony-ratio: 1.33 !default;

// Size
$width-text: 52rem !default;
$width-block: 52rem !default;
$width-block-wide: 78.75rem !default;
$width-container: 78rem !default;
$width-border: 2px !default;
$width-sidebar: 18.75rem !default;
$width-logo: 15rem !default;

// Spacing
$space-gap: 1rem;

// Radius
$border-radius: math.div($space-gap, 4);

// Responsive breakpoints
$breakpoint-small: 460px;
$breakpoint-medium: 640px;
$breakpoint-large: 960px;
$breakpoint-enormous: 1440px;
$breakpoint-ginormous: 2560px;

;@import "sass-embedded-legacy-load-done:7";