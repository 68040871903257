form {
    max-width: $width-block;
    margin: $space-gap * 2 auto;
}

.field {
    position: relative;
    width: 100%;

    &.field--checkbox {
        .help-block {
            display: none;
        }
    }

    label {
        display: block;
        user-select: none;
        cursor: pointer;
        color: $color-label;
        font-weight: 700;
        margin-bottom: 0.25rem;
    }

    input,
    textarea,
    select {
        padding: 0;
        width: 100%;
        font-family: $base-font;
        padding: 1rem 1.5rem;
        border: $width-border solid $color-primary;
        border-radius: 8px;
        background: $color-white;

        &:focus {
            outline: none;
        }
    }

    .asterisk-span {
        margin-left: 0.25rem;
        font-weight: 400;
    }

    &--checkbox,
    &--radio {
        label {
            color: $color-black;
            position: relative;
            top: 0;
            left: 0;
        }
    }

    &--checkbox-single {
        padding: 0;
        border: none;

        label {
            position: static;
            display: block;
            color: $color-black;
        }
    }

    // Error styles
    &--error,
    &--error.active {
        > label {
            color: $color-action;
        }

        border-color: $color-action;
    }

    &#field_id_wagtailcaptcha {
        border: none;
        background: none;
    }
    &.field--date {
        label {
            top: 0;
            left: math.div($space-gap, 3);
            transform: translateY(-50%);
            background: $color-white;
            font-size: $space-gap * 0.75;
            font-weight: 700;
            color: $color-primary;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
}

#field_id_category {
    border: none;
    background: none;
    margin-top: $space-gap * 1.5;
    label {
        color: $color-primary-lighter;
        font-size: 1.188rem;
        font-weight: 700;
    }
    .linkwidget {
        padding: 0.2rem 0;

        li {
            margin: $space-gap * 1.5 0;
            width: auto;
            padding: 0;
            &:before {
                content: none;
            }

            a {
                background: $color-black;
                color: $color-white;
                width: auto;
                padding: 0.5rem;
                font-size: 0.938rem;
                font-weight: 500;
                border-radius: 5px;
            }
            a.selected {
                background: $color-white;
                color: $color-primary;
            }
        }
    }
}
#field_id_tags {
    border: none;
    background: none;
    margin-bottom: 1rem;
    .field-label {
        color: $color-black;
        font-size: 1rem;
        background: none;
        font-weight: 700;
        padding-bottom: 0.75rem;
        line-height: 22.4px;
        text-align: left;
        top: unset;
        left: unset;
        padding: 0;
    }
    .checkbox {
        margin-right: 0.5rem;
    }
    .checkbox-label {
        background: $color-black;
        color: $color-white;
        width: auto;
        padding: 1px 8px 1px 8px;
        font-size: 0.875rem;
        margin: 0.2rem 0;
        color: $color-white;
        font-weight: 400;
        border-radius: 4px;
        line-height: 19.6px;
        text-align: center;
    }
    .checkboxinput:focus ~ label {
        outline-offset: 1px;
        outline: 1px auto;
        outline: 1px auto -webkit-focus-ring-color;
    }

    input:checked + .checkbox-label {
        background: $color-white;
        color: $color-primary !important;
    }

    .checkbox-status {
        @include visually-hidden;
    }
}

.help-block,
.help-inline {
    display: block;
    font-size: $space-gap;
    margin: 0;
    padding: math.div($space-gap, 2) 0;
    color: $color-grey;

    strong {
        font-weight: inherit;
        font-style: italic;
        color: $color-action;
    }
}

.field-wrapper__checkbox {
    .help-block,
    .help-inline {
        padding-left: $space-gap * 1.5;
    }
}

button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
    @extend .button;

    font-family: inherit;
    appearance: none;
}

// checkbox / radio
.checkbox,
.radio {
    display: flex;
    margin-right: $space-gap;

    label {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    input {
        &:focus {
            ~ label span:first-child {
                outline-offset: 1px;
                outline: 1px auto;
                outline: 1px auto -webkit-focus-ring-color;
            }
        }
    }
}

.radio-options,
.checkbox-options {
    display: inline-flex;
    flex-flow: row wrap;
}

.radioinput,
.checkboxinput {
    position: absolute;
    width: 1.25rem !important;
    height: 1.25rem;
    opacity: 0;
    z-index: 10;
}

.radio-status,
.checkbox-status {
    display: inline-block;
    width: $space-gap * 1.25;
    height: $space-gap * 1.25;
    border: $width-border solid $color-primary;
    position: relative;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: math.div($space-gap, 2);
    margin-top: 0.1375em;
    background: $color-white;
}

.checkbox-status {
    border-radius: 8px;
}

:checked {
    + label .radio-status:after {
        content: '';
        display: inline-block;
        width: $space-gap * 0.6;
        height: $space-gap * 0.6;
        background: $color-primary;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    + label .checkbox-status:after {
        content: '';
        display: inline-block;
        width: 0.2rem;
        height: 0.45rem;
        border-right: $width-border solid $color-primary;
        border-bottom: $width-border solid $color-primary;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -0.2875rem) rotate(45deg);
    }
}

.flatpickr-calendar {
    .flatpickr-day {
        border-radius: 0;

        &.today {
            border-color: $color-line;
        }

        &.selected,
        &.startRange,
        &.endRange,
        &.selected.inRange,
        &.startRange.inRange,
        &.endRange.inRange,
        &.selected:focus,
        &.startRange:focus,
        &.endRange:focus,
        &.selected:hover,
        &.startRange:hover,
        &.endRange:hover,
        &.selected.prevMonthDay,
        &.startRange.prevMonthDay,
        &.endRange.prevMonthDay,
        &.selected.nextMonthDay,
        &.startRange.nextMonthDay,
        &.endRange.nextMonthDay {
            background: $color-primary;
            border-color: $color-primary;
            color: $color-white;
        }
    }

    .flatpickr-months {
        .flatpickr-prev-month:hover,
        .flatpickr-next-month:hover {
            svg {
                fill: $color-primary;
            }
        }
    }
}
.thank-you {
    padding: 2.5rem 0 0 0;
}

.messages {
    @include unstyled-list;
    margin-top: 4.5rem;
    margin-bottom: -3rem;

    li {
        padding: 1rem;
        width: 100%;
        background-color: $color-secondary;
        font-weight: bold;
        text-align: center;
    }
}

;@import "sass-embedded-legacy-load-done:33";