summary {
    padding: 1rem;
    color: $color-black;
    border-radius: 8px;
    @include breakpoint(medium) {
        padding: 2rem;
    }
    &:hover {
        cursor: pointer;
    }
    h3 {
        font-family: $base-font;
        font-weight: 700;
        font-size: 32px;
        line-height: 35.2px;
        color: $color-white;
        margin-bottom: 0;
        text-transform: unset;
    }

    span {
        padding: 0 0.3rem;
    }
    .open {
        display: inline;
    }
    .closed {
        display: none;
    }

    // Hide the triangle marker that shows in some browsers
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    [open] & {
        background: $color-primary-darker;
        border-radius: 8px 8px 0 0;
    }
}
details {
    margin-bottom: 0.75rem;
    &[open] {
        .open {
            display: none;
        }
        .closed {
            display: inline;
        }
    }
    .rich-text {
        padding: 1rem;
        border: 2px solid $color-primary;
        border-radius: 0 0 8px 8px;
        border-top: none;
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: 0px;
        @include breakpoint(medium) {
            padding: 2rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:51";