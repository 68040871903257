.icon {
    width: 1.25em;
    height: 1.25em;
    fill: currentColor;
    vertical-align: middle;
    flex-shrink: 0;

    &--black {
        color: $color-black;
    }
    &--light {
        color: $color-secondary-darker;
    }
    &--action {
        color: $color-action;
    }

    &--end {
        margin: 0 0 0 auto;
    }

    &--small {
        width: 1rem;
        height: 1rem;
    }

    &--large {
        width: 1.75rem;
        height: 1.75rem;
    }

    &.space-left {
        margin-left: math.div($space-gap, 2);
    }

    &.space-right {
        margin-right: math.div($space-gap, 2);
    }

    &.share-icon {
        width: 1.25rem;
        height: 1.25rem;
        &:hover {
            color: $color-primary;
        }
    }
}

.header-links + label.button.icon-button {
    height: 6rem;
    width: 6rem;
    background: none;

    .menu--icon {
        width: 3rem;
        height: 3rem;
        background-color: $color-primary;
        color: $color-secondary;
        text-decoration: none;

        @include breakpoint(large) {
            width: 3rem;
            height: 3rem;
        }
    }
}

@include breakpoint(large) {
    .header-links + label.button.icon-button {
        height: 3rem;
        width: 3rem;
        background: none;
        margin: 0;
        padding: 3rem;

        .menu--icon {
            width: 3rem;
            height: 3rem;
            background-color: $color-primary;
            color: $color-secondary;
            text-decoration: none;
        }
    }
}

.detail-back {
    background-color: $color-primary !important;
    color: $color-white !important;
}

;@import "sass-embedded-legacy-load-done:35";