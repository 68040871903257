.column {
    .block:first-child {
        margin-top: 0;
    }

    .block {
        margin: 2rem 0;
        padding-right: 0 !important;
        padding-left: 0 !important;

        @include breakpoint(large) {
            margin: 3rem 0;
        }
    }

    .tile {
        display: block;
        margin-bottom: 2.5rem;

        @include breakpoint(large) {
            margin-bottom: 4rem;
        }
    }

    .cta-block {
        display: block;
        .cta-block__body {
            padding: 1.5rem 1rem;
        }
    }
}

.two-column-block {
    gap: 2.5rem;

    @include breakpoint(large) {
        gap: 2.75rem;
    }
}

;@import "sass-embedded-legacy-load-done:49";