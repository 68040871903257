.page-main.index__results {
    position: relative;
    padding: 0 1rem;
    @include breakpoint(large) {
        padding: 0;
    }
    .h3 {
        margin-top: 0;
        margin-left: 0.5rem;
    }
    h4 {
        font-style: normal;
        margin: 0 0 0.5rem 0;
    }
}
.field--select {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 4rem;
        right: 1.5rem;
        background: $color-white;
        border: solid $color-primary;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 0.2rem;
        transform: rotate(45deg);
        pointer-events: none;
    }
    select {
        appearance: none;
        -moz-appearance: none;
    }
    .field-wrapper__multiselect & {
        &:after {
            content: none;
        }
    }
}
.pagination {
    .button:not(.button--pagination):not(.button--pagination-active) {
        background: $color-white;
        color: $color-black;
        width: 40px;
        height: 40px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &:hover {
            background: #40654d;
            color: $color-white;
        }
        .icon {
            margin-left: 0;
            margin-right: 0;
            width: 1rem;
            height: 1rem;
        }
        &.button--disabled {
            background: $color-medium-grey;
            color: $color-white;
        }
    }
}
.index-page {
    background: $color-secondary;
    .index-page-banner {
        background: $color-white;

        padding-top: 3rem;
        margin-bottom: 5rem;
        position: relative;
        &:after {
            content: '';
            background-image: url(../img/index_page_banner.svg);
            height: 4rem;
            width: 100%;
            bottom: -4rem;
            position: absolute;
            background-size: 100% 100%;
        }
        .row {
            display: block;
            padding: 1rem;
            gap: 1rem;
            @include breakpoint(large) {
                display: flex;
                gap: 3rem;
            }
        }
        h1 {
            word-break: break-word;
            flex: 1;
            margin-bottom: 1rem;
            line-height: 0.8;
        }
        h2 {
            font-family: $base-font;
            font-weight: 700;
            font-size: 24px;
            line-height: 28.8px;
            text-transform: capitalize;
        }
        p {
            flex: 1;
        }

        .filter-container {
            margin-top: 0;
            padding: 1rem;
            padding-bottom: 3rem;
        }
        form {
            grid-template-columns: repeat(1, 1fr 220px);
            grid-template-rows: repeat(2, auto);
            max-width: $width-block-wide;
            column-gap: 2rem;
            row-gap: 0.5rem;
            @include breakpoint(large) {
                display: grid;
            }
            .field-wrapper {
                margin-bottom: 1rem;
            }
            .field-wrapper:last-of-type {
                grid-row: span 2;
            }
            .field > label {
                position: unset;
                height: unset;
                width: unset;
                clip: unset;
                clip-path: unset;
                font-family: Inter;
                font-weight: 700;
                font-size: 18px;
                line-height: 25.2px;
                letter-spacing: 0%;
                color: $color-black;
                padding-bottom: 0.25rem;
            }
            input,
            select {
                font-size: 18px;
            }
            .field--select:after {
                top: 3.5rem;
            }
        }

        .search-button {
            @include visually-hidden;
        }
    }
    .index__results {
        margin-top: -5rem;
        .list-item__image {
            position: relative;
            img {
                width: 300px;
                height: 300px;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.33) 0px 8px 16px;
            }
            .stack {
                position: absolute;
                background-color: $color-primary;
                color: $color-white;
                padding: 0.4rem 1rem;
                bottom: 0;
                border-radius: 0 8px 0 8px;
                span {
                    font-size: 18px;
                    font-weight: 700;
                    margin-top: 0;
                }
                span:last-of-type {
                    font-size: 22px;
                }
            }
        }
        .list-item__summary {
            p {
                font-size: 1.25rem;
                @include breakpoint(large) {
                    font-size: 1.5rem;
                }
            }
        }
        p.h4 {
            font-family: $base-font;
            font-weight: 700;
            font-size: 32px;
            line-height: 35.2px;
            text-transform: unset;
            text-decoration: none;
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
}
.pagination-container.block {
    margin-top: 1rem;
    margin-bottom: 7rem;
}
.radio-options {
    gap: 0.5rem;
    .radio-status {
        width: 0;
        height: 0;
        border: none;
        margin-right: 0;
        margin-left: 0.5rem;
        display: none;
    }
    :checked {
        + label .radio-status {
            display: block;
        }
        + label .radio-status:after {
            display: block !important;
            position: relative;
            content: '';
            background: url(../sprite/tick_white.svg) no-repeat center;
            background-size: contain;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0;
        }
        + label.domestic_abuse .radio-status:after {
            background: url(../sprite/tick_black.svg) no-repeat center;
            background-size: contain;
        }
        + label.young_people .radio-status:after {
            background: url(../sprite/tick_black.svg) no-repeat center;
            background-size: contain;
        }
        + label.supported_accomodation .radio-status:after {
            background: url(../sprite/tick_black.svg) no-repeat center;
            background-size: contain;
        }
        + label.well_being .radio-status:after {
            background: url(../sprite/tick_black.svg) no-repeat center;
            background-size: contain;
        }
        + label.dementia_support .radio-status:after {
            background: url(../sprite/tick_black.svg) no-repeat center;
            background-size: contain;
        }
        + label .radio-status {
            width: 1rem;
            height: 1rem;
            border: none;
            border-radius: 0;
            background-color: unset;
        }
    }
    .radio {
        margin-right: 0;
        margin-left: 0;
    }
    .radio-label {
        font-family: $base-font;
        font-weight: 700;
        font-size: 18px;
        line-height: 25.2px;
        text-align: center;
        padding-top: 4px;
        padding-right: 8px;
        padding-bottom: 4px;
        padding-left: 8px;
        border-radius: 4px;
        background: $color-black;
        color: $color-white;
        display: flex;
        flex-flow: row-reverse;
        margin-bottom: 0;

        &.resettlement {
            background: $color-tertiary;
            color: $color-white;
        }
        &.mental_health {
            background: $color-mental-health;
        }
        &.domestic_abuse {
            background: $color-secondary;
            color: $color-black;
        }
        &.young_people {
            background: $color-pink;
            color: $color-black;
        }
        &.supported_accomodation {
            background: $color-supported_accomodation;
            color: $color-black;
        }
        &.well_being {
            background: $color-tertiary-lighter;
            color: $color-black;
        }
        &.dementia_support {
            background: $color-dementia;
            color: $color-black;
        }
        &.inspire_north {
            background: $color-primary;
        }
    }
    .radioinpit:checked {
        background: red;
    }
}

;@import "sass-embedded-legacy-load-done:23";