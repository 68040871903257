.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color-black;
    border: none;
    color: $color-white;
    font-weight: 700;
    font-size: 1.25rem;
    cursor: pointer;
    user-select: none;
    position: relative;
    margin: 1rem 2rem;
    border-radius: 8px;
    padding: 0.5rem 1.25rem;
    line-height: 1.4;

    @include breakpoint(large) {
        font-size: 1.5rem;
    }

    &:hover {
        background: $color-grey;
        color: $color-white;
        text-decoration: none;
    }

    .icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    .icon:first-child {
        margin-right: math.div($space-gap, 2);
    }

    .icon:last-child {
        margin-left: math.div($space-gap, 2);
    }

    &.icon-button {
        height: 2.5rem;
        width: 2.5rem;

        .icon {
            margin: 0;
            padding: 0.2rem;
        }
    }

    &--action {
        background: $color-action;

        &:hover {
            background: $color-action-darker;
        }
    }

    &--secondary {
        background: $color-white;
        color: $color-black;
        border: 2px solid $color-black;

        &:hover {
            background: $color-black;
            color: $color-white;
        }
    }

    &--large {
        font-size: 1.375rem;
        padding: 0.625rem 2.5rem;
        min-height: 3.75rem;
    }

    &--small {
        font-size: 0.875rem;
        padding: 0.375rem 0.5rem;
    }

    &--center {
        justify-content: center;
    }

    &--disabled {
        pointer-events: none;
        background: $color-light-grey;
        color: $color-black;
    }

    &--light {
        background: $color-light-grey;
        margin: 0.2rem;
        color: $color-black;
        font-size: 0.75rem;
        padding: 0.3rem 0.5rem;

        .icon {
            fill: $color-black;
            width: 1rem;
            height: 1rem;
        }
        &:hover {
            background: $color-black;
            color: $color-white;
        }
    }

    &--social {
        background: none;
        margin: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
        color: $color-white;
        font-style: italic;

        .icon {
            fill: $color-white;
            width: 1.25em;
            height: 1.25em;
        }
        &:hover {
            background: none;
            .icon {
                fill: $color-primary-lighter;
            }
        }
    }
    &--pagination,
    &--pagination-active {
        background: $color-white;
        color: $color-black;
        font-weight: 500;
        text-align: center;
        padding: 0.3rem 0.6rem;
        margin: 0.1rem;
        font-weight: 400;
        font-size: 24px;
        line-height: 33.6px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        &:hover {
            background: #40654d;
        }
    }
    &--pagination-active {
        background: $color-primary !important;
        color: $color-white !important;
    }
}

.button-row {
    margin-top: 1rem;

    .button + .button {
        margin-left: math.div($space-gap, 2);
    }

    .button {
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
        margin-left: 0;
        margin-right: 0;
    }

    &.row--justify-left {
        .button:first-child {
            margin-left: 0;
        }
    }

    &.row--justify-end {
        .button:last-child {
            margin-right: 0;
        }
    }
}

// Dark Green Button
.button--colour {
    background: $color-primary;
    color: $color-white;
    border: none;
    transition: all 0.2s ease-in-out;

    .icon {
        color: $color-secondary;
    }

    &:focus {
        outline: 2px solid $color-secondary;
    }

    &:hover {
        background: #40654d;
    }

    &:disabled,
    &.disabled {
        background: $color-light-grey;
        color: $color-medium-grey;
        pointer-events: none;
    }
}

.button--outline {
    background: transparent;
    border: 2px solid $color-primary;
    color: $color-primary;

    &:hover {
        background: #103e201a;
        color: $color-primary;
    }
    &.disabled {
        border-color: $color-light-grey;
        color: $color-medium-grey;
        background: transparent;
        pointer-events: none;
    }

    .icon {
        color: $color-primary;
    }
}

.button--text-only {
    background: transparent;
    border: none;
    color: $color-primary;
    transition: all 0.2s ease-in-out;

    &:focus {
        outline: 2px solid $color-primary;
    }

    &:hover {
        color: $color-primary;
        background: #103e201a;
    }

    &:disabled,
    &.disabled {
        color: $color-medium-grey;
        text-decoration: none;
        pointer-events: none;
    }
}

;@import "sass-embedded-legacy-load-done:37";