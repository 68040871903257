img,
video {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

.video__container,
.rich-text .responsive-object {
    position: relative;
    max-width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    video,
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        max-width: none;
    }
}

.video__container {
    border-radius: 1rem;
}

figure {
    margin: auto;
}

figcaption {
    font-size: 0.625rem;
    text-align: left;
}

.rich-text {
    iframe {
        max-width: 100% !important;
    }
}
.block.video-block {
    margin-bottom: 6rem;
    display: block;
}

;@import "sass-embedded-legacy-load-done:39";