.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(large) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &--wide {
        width: 100%;
        max-width: $width-block-wide;
        margin-left: auto;
        margin-right: auto;
    }

    &--with-sidebar {
        display: grid;
        grid-gap: $space-gap * 2;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(large) {
            grid-template-columns: $width-sidebar 1fr;
            grid-gap: $space-gap;
        }
        .standardpage & {
            max-width: 78.75rem;
        }
    }
    &--with-right-sidebar {
        display: grid;
        grid-gap: $space-gap * 2;
        width: 100%;
        max-width: $width-container;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(large) {
            grid-template-columns: 1fr $width-sidebar;
            grid-gap: $space-gap;
        }
    }
}

.main {
    position: relative;
    &.newsindexpage,
    &.resourceindexpage,
    &.search {
        color: $color-black;
        .rich-text,
        .container:first-of-type {
            max-width: 79.5rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:16";