.gethelpandsupportpage {
    h1 {
        font-size: 4rem;
    }
    h2 {
        font-size: 3rem;
        line-height: 0.9;
    }

    form {
        margin-top: 0;
        width: 100%;
        .button {
            background: $color-primary;
            margin: 0;
            font-size: 20px;
            height: 56px;
            padding-top: 14px;
            padding-right: 16px;
            padding-bottom: 14px;
            padding-left: 8px;
            margin-left: -0.4rem;
            border-radius: 0 8px 8px 0;
        }
    }
    #field_id_postcode {
        input {
            height: 56px;
        }
    }
    #toggle-service-type {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4;
        vertical-align: middle;
        color: $color-black;
        margin: 0;
        padding: 0;
        &:hover {
            background: none;
            color: $color-primary;
        }
    }

    .field-wrapper:nth-of-type(1) {
        width: 100%;
        .field {
            input,
            select {
                border: 2px solid $color-primary;
                font-size: 18px;
                height: 3.1rem;
                padding: 0.5rem;
            }
            label {
                font-size: 18px;
                color: #6b6b6b;
                display: block;
                user-select: none;
                cursor: pointer;
                position: absolute;
                top: 1rem;
                left: 0.3333333333rem;
                padding: 0 0.25rem;
                pointer-events: none;
                font-weight: 400;
            }
        }
    }
    .field-wrapper:nth-of-type(2) {
        display: none;
        position: absolute;
        background: $color-secondary;
        top: 9rem;
        left: 0;
        width: 100%;
        height: max-content;
        &.show-filter {
            display: block;
        }
        .field-label {
            @include visually-hidden;
        }
        .radio-options {
            padding: 1rem;
        }
    }

    .services-list-header {
        background: $color-secondary;
        padding: 1.5rem;
    }
    .index__results {
        justify-content: space-between;
        flex-direction: column-reverse;
        order: 1;
        @include breakpoint(large) {
            flex-direction: row;
        }
        .stack {
            overflow-y: auto;
            @include breakpoint(large) {
                width: 33.3%;
                max-height: 90vh;
            }
        }
        .list-item {
            .row {
                flex-flow: column wrap;
                gap: 1rem;
                @include breakpoint(medium) {
                    flex-flow: row wrap;
                    align-items: center;
                }
                @media (min-width: 1600px) {
                    flex-flow: row nowrap;
                }
            }
            a {
                .h4 {
                    font-family: $base-font;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 35.2px;
                    text-transform: none;
                    margin-bottom: 0.5rem;
                }
            }
            .list-item__content {
                padding: 0;
            }
            .list-item__image {
                width: 134px;
                height: 134px;
                box-shadow: 0px 8px 16px -4px #00000033;
                flex-shrink: 0;

                img {
                    border-radius: 8px;
                }
            }
            .service-type {
                width: fit-content;
            }
        }
    }
    .index__list {
        position: relative;
        .box {
            @include breakpoint(large) {
                padding: 1rem 2rem;
            }
        }
    }
    .index__seperator {
        height: 3.375rem;
        width: 100%;
        background: $color-primary;
    }
    .get-help-block-container {
        position: relative;
        height: 20rem;
        width: 100%;
        @include breakpoint(large) {
            height: 89vh;
            width: 66.6%;
        }
        &.full-width {
            @include breakpoint(large) {
                height: 89vh;
                width: 100%;
            }
            #get-help-map {
                width: 100vw;
            }
        }
        button.icon-button {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            .icon {
                color: $color-white;
            }
        }
        #close-filter-button {
            display: none;
            @include breakpoint(large) {
                display: flex;
            }
        }
    }
    #get-help-map {
        height: 20rem;
        width: 100%;
        z-index: 0;
        @include breakpoint(large) {
            height: 90vh;
            width: 100%;
        }
        .leaflet-popup-content-wrapper {
            border-radius: 8px;
        }
    }
    .leaflet-container a {
        color: $color-primary;
    }
}
.servicedetailpage {
    .button--social {
        .icon {
            fill: $color-black;
        }
    }
    .grid.grid--stack-left {
        h2.h3:first-child {
            margin-top: 0 !important;
        }
    }
}

;@import "sass-embedded-legacy-load-done:27";