.logo {
    max-width: 10rem;
    transition: all 0.3s ease;

    @include breakpoint(medium) {
        max-width: 12rem;
    }

    @include breakpoint(large) {
        max-width: $width-logo;
    }
}

;@import "sass-embedded-legacy-load-done:40";