.map-block {
    #get-help-map {
        height: 20rem;
        width: 100%;
        z-index: 1;
        @include breakpoint(large) {
            height: 40rem;
            width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:63";