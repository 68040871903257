.pebbletrail {
    .pebbletrail-link {
        display: inline-flex;
        align-items: center;
        color: $color-black;
        text-decoration: underline;
        font-weight: 400;
        font-size: 14px;
        &.active {
            font-weight: 700;
            text-decoration: none;
        }
        .icon {
            margin-right: math.div($space-gap, 4);
        }
    }

    .pebbletrail-separator {
        color: $color-black;
        .icon {
            height: 14px;
            width: 14px;
        }
    }
}
.pebbletrail-container {
    max-width: 108rem;
    margin: 0 auto;
}

.primary-navigation {
    position: relative;

    @include breakpoint(medium) {
        font-size: 1rem;
    }

    @include breakpoint(large) {
        font-size: 1.125rem;
    }

    a {
        display: inline-block;
        padding: math.div($space-gap, 2);
        white-space: nowrap;
        color: $color-white;
        margin-right: 1rem;
        border: 2px solid transparent;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.4;

        &:hover {
            border-radius: 5px;
            border: 2px solid $color-primary;
        }
        .icon {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    .active a {
        border-radius: 5px;
        border: 2px solid $color-primary;
    }
    .dropdown {
        &[aria-hidden='false'] {
            display: block;
        }

        display: block;
        opacity: 0;
        background: $color-white;
        position: absolute;
        width: 12.375rem;
        pointer-events: none;
        box-shadow: 0px 0.25rem 0.75rem -0.375rem rgba($color-black, 0.125);
    }
    li:hover {
        > .dropdown {
            opacity: 1;
            pointer-events: auto;
            z-index: 100;

            ul {
                margin: 0;
                padding: 0.5rem;
                font-size: 0.9rem;
                font-weight: 400;
            }
            li {
                padding: 0;
            }
            a {
                white-space: pre-wrap;
                border: none;
            }
        }
    }
}

.footer-navigation {
    margin-right: $space-gap;

    > li > span {
        @extend .font-size-l;

        color: $color-primary;
        font-weight: 700;
    }

    .stack {
        align-items: flex-start;
    }

    .row {
        width: auto;
    }

    .sub-tree {
        border-color: transparent;
        margin-top: math.div($space-gap, 2);

        span {
            background: $color-grey;
            border-radius: $border-radius;
        }
        > a {
            color: $color-black;
        }
        > .nav-tree__link {
            color: $color-black;
        }
    }

    .sub-tree.stack--lined {
        > * + * {
            margin-top: 2px;
            border-color: transparent;
        }
    }
}

.footer__navigation__quicklinks {
    padding-top: 2.325rem;

    .stack > * + * {
        margin-top: 4px;
    }
}

.sub-tree__toggle {
    background: none;
    border: none;
    margin: 0;
    color: $color-secondary;
    cursor: pointer;

    &:hover {
        background: none;
    }

    .open,
    .close {
        margin: 0 0 0 -1.5rem;
        color: $color-secondary;
        width: 2rem;
        height: 2rem;
    }

    .close {
        display: none;
    }
    @include breakpoint(medium) {
        display: none;
    }
}

.active {
    // Switch open / closed display
    > span {
        .open {
            display: none;
        }

        .close {
            display: block;
        }
    }

    > .mobile-dropdown {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }
}

.sub-tree {
    display: none;

    @include breakpoint(large) {
        display: flex;
    }

    .show-all & {
        display: flex;
    }
}

.sub-tree {
    gap: 1rem;
    .nav-tree__link {
        padding-left: 2rem;
        position: relative;
        .icon {
            position: absolute;
            left: 0;
            fill: $color-secondary;

            use {
                color: $color-secondary;
            }
        }
    }
}

.mobile-navigation {
    background-color: $color-white;
    color: $color-black;
    position: relative;

    @include breakpoint(large) {
        background-color: unset;
        color: $color-white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    a {
        display: block;
        flex: 1;
        min-width: 100%;
        color: $color-black;
        font-size: 1rem;

        @include breakpoint(large) {
            color: $color-white;
        }
    }

    > .nav-tree__item {
        padding: 1rem 1.5rem;

        @include breakpoint(large) {
            padding: 2rem;
        }

        & > span .nav-tree__link {
            margin-bottom: 0;
            font-size: 1.5rem;

            @include breakpoint(large) {
                margin-bottom: 0.5rem;
            }

            .icon {
                display: none;
            }
        }
    }

    // Top level of mobile navigation are larger and blue
    > li > span {
        color: $color-white;
        font-size: 1.375rem;

        .sub-tree__toggle {
            @include breakpoint(large) {
                display: none;
                color: $color-white;
            }
        }
    }

    > .nav-tree__item > .sub-tree {
        border-color: $color-primary-darker;
        margin-top: 1rem;
    }
    .nav-tree__item .row > .icon {
        display: none;
    }

    .sub-tree .sub-tree {
        padding-left: $space-gap * 2.5;
        border-top: none;

        a {
            padding: $space-gap 0;
        }

        > li {
            position: relative;

            + li {
                margin-top: 0;
            }

            &:first-child {
                border-top: $width-border solid $color-medium-grey;
            }

            &::before {
                content: '';
                display: block;
                width: math.div($space-gap, 2);
                height: math.div($space-gap, 2);
                border-left: $width-border solid $color-medium-grey;
                border-bottom: $width-border solid $color-medium-grey;
                position: absolute;
                top: 1em;
                left: -$space-gap * 1.375;
                transform: translateY(-50%);
            }
        }
    }
}

.sidebar-navigation {
    margin: 0 0 $space-gap * 2 $space-gap;

    a {
        padding: $space-gap;
        color: $color-black;
        font-size: 14px;
        line-height: 1.4;
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .sub-tree {
        padding-left: $space-gap * 2.5;
        border-top: none;

        > li {
            position: relative;

            + li {
                margin-top: 0;
            }

            &:first-child {
                border-top: $width-border solid $color-medium-grey;
            }

            &::before {
                content: '';
                display: block;
                width: math.div($space-gap, 2);
                height: math.div($space-gap, 2);
                border-left: $width-border solid $color-medium-grey;
                border-bottom: $width-border solid $color-medium-grey;
                position: absolute;
                top: 1em;
                left: -$space-gap * 1.375;
                transform: translateY(-50%);
            }
            a.current-page {
                font-weight: 700;
                color: $color-primary;
            }
        }
    }
    .sub-tree__toggle {
        .icon {
            margin-right: 0.5rem;
        }
    }
}

.root-parent-item {
    font-size: 1.5rem !important;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1rem;

    &:hover {
        text-decoration: none;
    }

    .icon {
        display: inline-block;

        @include breakpoint(large) {
            display: none;
        }
    }
}

.icon-right {
    margin: 0.25rem 0.5rem 0 0;
    width: 0.8rem;
    height: 0.8rem;
}

.nav-tree__root .icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-white;
    margin-left: 0.5rem;
}

.icon-left {
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-secondary;
}

;@import "sass-embedded-legacy-load-done:41";