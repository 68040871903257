.four {
    position: relative;
    height: 47rem;
    background-size: cover;
    h1 {
        color: $color-black;
        margin-bottom: 0;
        @include breakpoint(large) {
            font-size: 15rem;
            line-height: 1;
        }
    }
    h2 {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        line-height: 1.2;
    }
    p.h5 {
        padding: 0.5rem;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.7;
        text-transform: none;
        font-weight: 400;
    }
}

;@import "sass-embedded-legacy-load-done:24";