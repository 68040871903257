.block {
    margin: $space-gap * 3 auto;
    @include breakpoint(large) {
        margin: $space-gap * 9.5 auto $space-gap * 2;
    }

    &:first-of-type:not(.cta-block) {
        margin-top: 0;
    }
}
.block.rich-text {
    margin-bottom: 3rem;
    margin-top: 2rem;
    h2,
    h3,
    h4 {
        margin-bottom: 1rem;
    }
    h4 {
        font-family: $base-font;
    }

    a {
        color: $color-black;
        text-decoration: underline;
        &:hover {
            color: $color-primary;
        }
    }
}

@import 'columns';
@import 'tile';
@import 'concertina';
@import 'image';
@import 'quote';
@import 'html';
@import 'auto_top_three';
@import 'content_list';
@import 'cta';
@import 'form_block';
@import 'highlight';
@import 'shape_tile';
@import 'sections';
@import 'resource_list';
@import 'map_block';
@import 'feature';

;@import "sass-embedded-legacy-load-done:48";