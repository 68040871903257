.homepage-banner {
    background: $color-primary;
    .banner__container {
        background-image: url('../img/oval-extra-wide.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 235% 100%;
        @media (min-width: 400px) {
            background-size: 200% 100%;
        }

        @include breakpoint(small) {
            background-size: 176% 100%;
        }
        @media (min-width: 512px) {
            background-size: 146% 100%;
        }

        @include breakpoint(medium) {
            background-size: 132% 100%;
        }
        @include breakpoint(large) {
            background-size: 110% 100%;
        }
        @include breakpoint(enormous) {
            background-size: 100% 100%;
        }
    }
    .banner-content {
        max-width: 38.625rem;
        margin: auto;
        padding: 4rem 2rem;
        @include breakpoint(small) {
            max-width: 65.375rem;
            padding: 5rem 2rem;
        }
        @include breakpoint(large) {
            padding: 6rem 1rem;
            max-width: 65.375rem;
        }
    }
    .banner-content__buttons {
        gap: 0.3rem;
        flex-flow: column;
        @include breakpoint(large) {
            gap: 1rem;
            flex-flow: row;
        }
        .button {
            margin: 0;
        }
    }
    h1 {
        text-align: center;
        font-size: 3rem;
        line-height: 43.2px;

        @include breakpoint(large) {
            font-size: 5.5rem;
            line-height: 70.4px;
        }
    }
    p {
        text-align: center;
        font-family: Inter;
        font-weight: 400;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: 0px;
        text-align: center;
        color: $color-primary;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        @include breakpoint(large) {
            font-family: Inter;
            font-weight: 400;
            font-size: 24px;
            line-height: 33.6px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:22";