.static_header {
    display: none;
    @include breakpoint(large) {
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 11;
        transition: all 0.5s;

        .static_header--nav {
            height: auto;
        }
        .search-link {
            padding: 0.5rem;
            margin-right: 1rem;
            .icon {
                width: 1.75rem;
                height: 2rem;
            }
        }
    }
}

.hide {
    opacity: 0;
    display: none;
    top: -100%;
}
.show {
    opacity: 1;
    display: block;
}

;@import "sass-embedded-legacy-load-done:20";