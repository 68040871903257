.header {
    position: relative;
    z-index: 10;
    background: $color-primary;

    .logo {
        margin: 0.5rem 1rem 1rem;
        position: absolute;
        @include breakpoint(medium) {
            position: relative;
        }

        svg {
            height: 4rem;
            @include breakpoint(large) {
                height: 5rem;
                margin-top: 1rem;
                margin-left: 1rem;
            }
        }

        ~ label.button {
            order: 2;
            margin: 0;
        }

        @include breakpoint(enormous) {
            order: unset;
        }
    }

    a {
        user-select: none;
        color: $color-secondary;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.4;
    }

    .nav--wrapper {
        gap: 1rem;
        justify-content: space-between;
        align-items: flex-end;
        @include breakpoint(large) {
            align-items: unset;
        }
        .stack {
            width: 100%;
            @include breakpoint(large) {
                width: unset;
            }
        }
    }

    &.header-sticky {
        position: fixed;
        width: 100%;
        transition: all 0.3s ease;
        box-shadow: 0 1rem 1rem -0.5rem rgba($color-black, 0.4);
    }
}

// Header links from Setting Header
.header {
    .box {
        margin-top: 0;
        padding-top: 0;
    }
    .stack > * {
        margin-top: 0;
    }
}

.header-links {
    position: relative;
    margin-bottom: auto;
    margin-left: auto;
    display: flex;
    gap: 1rem;
    flex: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin-right: 1rem;
    @include breakpoint(small) {
        padding: 0 1rem;
    }

    @include breakpoint(enormous) {
        flex: unset;
        padding: 0;
    }
}

.block-link {
    display: inline-block;
    border-radius: 0 0 5px 5px;
    padding: 1rem;
    flex: 1;
    padding-top: 10px;
    padding-right: 12px;
    padding-bottom: 10px;
    padding-left: 12px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: $color-black;
    &:hover {
        background: $color-white;
        a {
            color: $color-black;
        }
    }

    a {
        text-decoration: none;
        font-size: 16px;
        line-height: 1.4;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        @include breakpoint(enormous) {
            font-size: 18px;
        }

        .icon {
            margin-right: 0.5rem;
        }
    }
    &:first-of-type {
        display: none;
        @include breakpoint(large) {
            display: block;
        }
    }
}
.js-translate-button {
    margin: 0;
    background-color: $color-black;
    display: flex;
    width: unset;
    display: inline-block;
    border-radius: 0 0 5px 5px;
    padding: 7.2px 12px 7px;
    flex: 1;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    select {
        width: 100px;
    }
    @include breakpoint(small) {
        font-size: 16px;
        padding: 10px 12px 10px 8px;
    }
    @include breakpoint(medium) {
        width: 140px;
        padding-right: 12px;
    }
    @include breakpoint(large) {
    }
    @include breakpoint(enormous) {
        width: 143px;
        span {
            font-size: 18px !important;
        }
    }
    @include breakpoint(ginormous) {
    }
    span {
        color: $color-white;
        font-size: 1rem;
        svg {
            display: none;
            @include breakpoint(small) {
                display: unset;
            }
        }
    }
    .goog-te-gadget {
        display: grid;
    }
}
.js-translate-button:hover {
    background-color: $color-white;
    span {
        color: $color-black;
    }
}

.block-link:nth-child(5) {
    background-color: #e50045;
    a {
        color: $color-white;
    }
    &:hover {
        background-color: darken(#e50045, 10%);
    }
}

.block-link:nth-child(4) {
    background-color: $color-action;
    color: black;
    a {
        color: $color-black;
    }
    &:hover {
        background-color: darken($color-action, 10%);
    }
}

.header-mobile {
    display: none;
    flex-flow: column nowrap;
    width: 100%;
    overflow: auto;
    top: 0;
    left: 0;
    background-color: darken($color-primary, 5%);
    transform: translate(0, -100%);
    transition: all 0.5s ease-in-out;
    height: 86vh;
    overflow-x: hidden;
    overflow-y: auto;

    > * {
        flex-shrink: 0;
        z-index: 100;
    }

    &__navigation {
        flex: 1;
        overflow: auto;
        background: $color-white;
    }
    .nav-container {
        padding: 1rem;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 80px;
        }
        a {
            font-weight: 700;
            font-size: 24px;
            line-height: 1.2;
            color: $color-black;
        }
    }
    .header-mobile__home-link {
        border-top: $width-border solid $color-primary-darker;
    }
    .header-sub-meta {
        display: none;
    }
    .mobile-dropdown {
        position: absolute;
        right: -100%;
        left: 100%;
        top: 0;
        transition: all 0.75s ease;
        z-index: 1;
        background: $color-white;

        .nav-back-button {
            width: 100%;
            border-radius: 0;
            background: $color-secondary;
            color: $color-black;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.4;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            justify-content: flex-start;
            padding: 1rem;
            .icon {
                width: 10px;
                height: 10px;
            }
        }
        .header-sub-menu {
            padding: 0 1rem 1rem 1rem;
            background: white;
        }
    }
}

.header-desktop {
    a {
        color: $color-white;
    }
}

#nav_toggle {
    display: block;
    @include breakpoint(small) {
        display: none;
    }
}
// Nav toggled styles
#nav_toggle:checked {
    ~ .header-controls {
        display: none;
    }

    ~ .header-mobile {
        display: flex;
        transform: translate(0, 0);
        @include breakpoint(medium) {
            display: none;
        }
    }
    + .nav--wrapper label.button {
        background-color: darken($color-primary, 5%);
        border-radius: 0;

        .icon {
            background-color: transparent;
        }
    }
}
#desktop_nav_toggle {
    display: none;
    @include breakpoint(small) {
        display: block;
    }
    @include breakpoint(small) {
        display: none;
    }
}
#desktop_nav_toggle:checked {
    @media (min-width: 640px) and (max-width: 960px) {
        ~ .desktop-navigation {
            .tablet-dropdown {
                display: block;
                position: absolute;
                top: 100%;
                width: 100%;
                left: 0;
                right: 0;
                background: $color-primary;
                overflow: auto;
                height: 85vh;

                ul.nav-container {
                    display: grid;
                    grid-template-columns: 2fr repeat(4, 1fr);
                    grid-template-rows: repeat(6, 1fr);
                    grid-column-gap: 8px;
                    grid-row-gap: 8px;
                    background: white;
                    border-top: 4px solid $color-medium-grey;
                    .dropdown {
                        border-radius: 0 0 10px 10px;
                        height: calc(100% - 8rem);
                        padding: 1rem 0;
                        .header-sub-meta {
                            overflow: hidden;
                            margin-right: -1rem;
                            margin-top: -1rem;
                            p {
                                width: 50%;
                            }
                        }
                    }
                    > li {
                        background: $color-primary;
                        display: flex;
                        flex-flow: column;
                        position: relative;
                        overflow: hidden;
                        min-height: 85px;

                        img {
                            mask-image: url(../img/oval-wide.svg);
                            mask-position: right;
                            mask-repeat: no-repeat;
                            mask-size: 100% 121%;
                            object-fit: contain;
                            margin-top: -1.5rem;
                            margin-bottom: -1.5rem;
                            width: 88%;
                            position: relative;
                            right: -5rem;
                            top: 0;
                            position: absolute;
                            width: 335px;
                            height: calc(100% + 2rem);
                        }
                        a {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 120%;
                            padding: 1.5rem;
                            color: $color-white;
                            height: 100%;
                            &:hover,
                            &:focus {
                                background: $color-secondary;
                                color: $color-black;
                                p {
                                    color: $color-black;
                                }
                            }
                        }
                        p {
                            font-family: Inter;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                            color: $color-white;
                            margin-top: 0.5rem;
                        }
                    }

                    li:first-of-type {
                        grid-area: 1 / 1 / content-end/ 2;
                        background: $color-tertiary;
                        display: block;
                        padding: 24px;
                        min-width: 33%;
                        height: 100%;
                        padding-bottom: 8rem;
                        ~ .dropdown {
                            padding: 0;
                        }

                        a {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 1.2;
                            vertical-align: middle;
                            padding: 0;
                            color: $color-white;
                            &:hover {
                                background: none;
                                color: $color-secondary;
                                ~ .dropdown {
                                    position: relative;
                                    top: unset;
                                    min-width: unset;
                                    p {
                                        max-width: 55%;
                                    }
                                }
                            }
                        }
                        .tablet-image {
                            display: block;
                            img {
                                mask-position: top center;
                                right: 0;
                                left: 0;
                                height: 250px;
                                top: unset;
                                bottom: -10%;
                                margin-bottom: 0;
                                mask-size: 112% 127%;
                            }
                        }

                        .header-sub-menu-item {
                            padding: 0.5rem 1rem 0.5rem 1.5rem;
                            position: relative;
                            &:before {
                                content: '';
                                background: url(../sprite/chevron-right-green.svg);
                                height: 17px;
                                width: 1rem;
                                background-size: contain;
                                background-repeat: no-repeat;
                                color: $color-secondary;
                                left: 0;
                                top: 0.8rem;
                            }
                            a {
                                font-size: 16px;
                            }
                        }
                        .dropdown {
                            display: block;

                            .header-sub-meta {
                                display: none;
                            }
                            .header-sub-menu {
                                display: block;
                            }
                        }
                    }
                    li:nth-of-type(2) {
                        grid-column: 2 / 6;
                    }
                    li:nth-of-type(3) {
                        grid-column: 2 / 6;
                    }
                    li:nth-of-type(4) {
                        grid-column: 2 / 6;
                    }
                    li:nth-of-type(5) {
                        grid-column: 2 / 6;
                    }
                    li:nth-of-type(6) {
                        grid-column: 2 / 6;
                    }
                    li:nth-of-type(7) {
                        grid-column: 2 / 6;
                    }
                }
            }
            label.button {
                background-color: darken($color-primary, 5%);
                border-radius: 0;
                color: $color-secondary;

                .icon {
                    background-color: transparent;
                }
            }
        }
    }
}

@media (min-width: 960px) {
    .dropdown {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
}

.search-wrapper {
    padding: 1rem 1.5rem;
    background-color: darken($color-primary, 5%);
    @include breakpoint(large) {
        display: none;
    }
}
.search-form__input {
    border: 2px solid $color-secondary;
    background-color: $color-white;
    border-radius: 10px;
    width: 100%;
    position: relative;
    margin: 0;
    font-size: 1.125rem;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 1rem;

    input {
        border: none;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 80%;
    }
    button {
        background: $color-primary;
        position: relative;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0;

        .icon {
            fill: $color-white;
        }
    }
}
.desktop-navigation {
    .tablet-dropdown {
        display: none;
        @include breakpoint(large) {
            display: flex;

            ul {
                display: flex;
                flex-flow: row wrap;
                margin-right: 1rem;
                margin-top: 3px;
                li {
                    gap: 8px;
                    display: flex;
                    position: relative;

                    .dropdown {
                        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 10px;
                        border-radius: 0 0 10px 10px;
                        display: none;
                    }
                    &:nth-last-child(4),
                    &:nth-last-child(3),
                    &:nth-last-child(2),
                    &:nth-last-child(1) {
                        a:hover,
                        a:focus {
                            .dropdown {
                                left: unset;
                                right: 0;
                            }
                        }
                    }
                    a {
                        color: $color-white;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.2;
                        padding-top: 16px;
                        padding-right: 16px;
                        padding-bottom: 16px;
                        padding-left: 16px;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        position: relative;
                        @media (min-width: 1200px) {
                            padding-bottom: 16px;
                            padding-top: 16px;
                            font-size: 24px;
                        }
                    }

                    a:hover,
                    a:focus {
                        background: $color-secondary;
                        color: $color-black;

                        .dropdown,
                        ~ .dropdown {
                            display: flex;
                            position: absolute;
                            top: 52px;
                            left: 0;
                            background: $color-secondary;
                            min-width: 28.4375rem;
                            width: 100%;
                            overflow: hidden;
                            z-index: 1;
                            pointer-events: none;
                            @media (min-width: 1200px) {
                                top: 3.8rem;
                            }
                            .header-sub-meta {
                                display: flex;

                                p {
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 1.4;
                                    padding: 2rem;
                                    min-width: 10rem;
                                    color: black;
                                }
                                img {
                                    mask-image: url(../img/oval-wide.svg);
                                    mask-position: right;
                                    mask-repeat: no-repeat;
                                    mask-size: contain;
                                    width: 100%;
                                    object-fit: cover;
                                    margin-top: -1.5rem;
                                    margin-bottom: -1.5rem;
                                    width: 75%;
                                }
                            }
                            .header-sub-menu {
                                @include breakpoint(large) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .tablet-image {
                display: none;
            }
        }
    }
    label.desktop-nav {
        background-color: unset;
        margin: 0.5rem 0 0 0;
        padding: 1.5rem;

        padding-top: 28px;
        padding-right: 24px;
        padding-bottom: 28px;
        padding-left: 24px;
        color: $color-secondary;

        display: none;
        @include breakpoint(medium) {
            display: flex;
        }
        @include breakpoint(large) {
            display: none;
        }
        &:checked {
            background-color: #0b2a16 !important;
        }
    }
    label.mobile-nav {
        display: flex;
        background-color: unset;
        margin: 0.5rem 0 0 0;
        padding: 1.5rem;

        padding-top: 28px;
        padding-right: 24px;
        padding-bottom: 28px;
        padding-left: 24px;
        color: $color-secondary;
        @include breakpoint(medium) {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:17";