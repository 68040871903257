* {
    box-sizing: border-box;

    // Limit max-width 80 characters
    max-width: 90rem;
}

:first-child {
    margin-top: 0;
}

:last-child {
    margin-bottom: 0;
}

html,
body,
div,
header,
footer,
.section-block,
nav,
main,
aside,
figure,
video,
img {
    max-width: none;
}

body,
html {
    overflow-x: hidden;
}

;@import "sass-embedded-legacy-load-done:5";