.list-item {
    width: 100%;

    .list-item__image {
        width: 100%;
        max-height: 19.75rem;
        @include breakpoint(large) {
            width: 19.75rem;
            max-height: none;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .list-item__content {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;

        @include breakpoint(large) {
            width: 100%;
            flex: 1;
            padding: 3rem 2rem;
        }
        .h3 {
            color: $color-black;
            margin-left: 0;
            margin-bottom: 0;
            &:hover {
                color: $color-black;
            }
        }
        p:not(.h4) {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.4;
        }

        .button {
            margin: 1rem 0;
        }
    }
    &__meta {
        margin-bottom: 1rem;

        span {
            padding-top: 6px;
            padding-right: 8px;
            padding-bottom: 6px;
            padding-left: 8px;
            border-radius: 4px;
            font-family: $base-font;
            font-weight: 700;
            font-size: 18px;

            line-height: 25.2px;
        }
        span.mental_health {
            color: $color-white;
            background: $color-mental-health;
        }
        span.domestic_abuse {
            background: $color-environment;
        }
        span.well_being {
            background: $color-tertiary-lighter;
        }
        span.resettlement {
            background: $color-tertiary;
            color: $color-white;
        }
        span.young_people {
            background: $color-pink;
        }

        span.supported_accomodation {
            background: $color-supported_accomodation;
        }
        span.dementia_support {
            background: $color-dementia;
        }
        span.inspire_north {
            background: $color-primary;
            color: $color-white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:43";