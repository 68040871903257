.feature-block {
    border-radius: 8px;
    min-height: 404px;
    height: 100%;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.33));

    .feature-block__link {
        text-decoration: none;
    }

    .feature-block__image {
        display: flex;
        align-items: flex-end;
        border-radius: 8px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .feature-block__title {
        width: 100%;
        padding: 2rem;
        padding-top: 8rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0.5) 50%,
            rgba(255, 255, 255, 0) 100%
        );

        h3 {
            font-family: $base-font;
            text-transform: none;
            font-size: 1.5rem;
            line-height: 1.2;
            color: $color-white;
        }
    }

    @include breakpoint(large) {
        min-height: 865px;

        .feature-block__title {
            padding: 3rem;
            padding-top: 8rem;

            h3 {
                font-family: 'Artz';
                text-transform: uppercase;
                font-size: 4rem;
                line-height: 3.5rem;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:64";