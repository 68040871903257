.standard-page-banner {
    background: $color-secondary;
    padding-top: 3rem;
    margin-bottom: 5rem;
    position: relative;
    &:after {
        content: '';
        background-color: $color-secondary;
        mask-image: url(../img/banner-shape.svg);
        -webkit-mask-image: url(../img/banner-shape.svg);
        height: 5rem;
        width: 100%;
        bottom: -5rem;
        position: absolute;
        z-index: -1;
    }
    .row {
        display: block;
        padding: 1rem;
        gap: 1rem;
        @include breakpoint(large) {
            display: flex;
            gap: 3rem;
        }
    }
    h1 {
        word-break: break-word;
        flex: 1;
        margin-bottom: 1rem;
        line-height: 0.8;
        padding-left: 3rem;
        @include breakpoint(large) {
            padding-left: 0;
        }
    }
    p {
        flex: 1;
        padding-left: 3rem;
        @include breakpoint(large) {
            padding-left: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:26";