.login-container {
    background-color: $color-secondary;
    background-image: url('../img/petal_small.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150% 100%;

    @include breakpoint(small) {
        background-image: url('../img/petal.svg');
        background-size: 150% 150%;
    }

    @include breakpoint(large) {
        background-image: url('../img/petal.svg');
        background-size: 108% 114%;
    }

    h1 {
        margin-top: 0;
        font-family: $base-font;
        text-transform: none;
        font-size: 3rem;
    }

    .login-form {
        max-width: 38.625rem;
        margin: 0 auto;
        padding: 7rem 0;

        @include breakpoint(large) {
            padding: 10rem 0;
        }
    }
}

.password-reset-link a {
    font-size: 1.125rem;
    color: $color-primary;
}

;@import "sass-embedded-legacy-load-done:29";