.image-block {
    text-align: center;
    position: relative;
    margin: 3rem -1rem;

    figure {
        display: inline-block;
        width: 100%;
    }
}

.image-block__credit {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    color: $color-primary-darker;
    a {
        color: $color-primary-darker;
    }
}

.image-block__image {
    img {
        &:not(.image-grid-block &) {
            mask-image: url(../img/slanted-rectangle-rotated.svg);
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            width: 100%;
        }
    }
    a {
        display: block;
    }
}

.image-grid-block {
    columns: 3 18.5rem;
    column-gap: 0.5rem;

    .image-block {
        margin: 0 0 1rem 0;
        width: 100%;
        img {
            border-radius: 8px;
            height: 14.5781rem;
            object-fit: cover;
        }
    }

    @include breakpoint(medium) {
        margin-bottom: $space-gap * 5;
    }
}

.richtext-image {
    &.full-width {
        width: 100%;
    }

    &.left {
        float: left;
        max-width: 50%;
        margin: 0 1rem 1rem 0;
    }

    &.right {
        float: right;
        max-width: 50%;
        margin: 0 0 1rem 1rem;
    }
}

// Clearfix for floating rich text images
.rich-text::after {
    content: '';
    clear: both;
    display: table;
}

;@import "sass-embedded-legacy-load-done:52";