// Desktop devices
@mixin breakpoint($size) {
    @if $size == ginormous {
        @media (min-width: #{$breakpoint-ginormous}) {
            @content;
        }
    }

    @if $size == enormous {
        @media (min-width: #{$breakpoint-enormous}) {
            @content;
        }
    }

    @if $size == large {
        @media (min-width: #{$breakpoint-large}) {
            @content;
        }
    }

    @if $size == medium {
        @media (min-width: #{$breakpoint-medium}) {
            @content;
        }
    }

    @if $size == small {
        @media (min-width: #{$breakpoint-small}) {
            @content;
        }
    }
}

.visible-large {
    display: none !important;

    @include breakpoint(large) {
        display: block !important;
    }

    &--flex {
        display: none !important;

        @include breakpoint(large) {
            display: flex !important;
        }
    }
}

.hidden-large {
    @include breakpoint(large) {
        display: none !important;
    }
}

.visible-medium {
    display: none !important;

    @include breakpoint(medium) {
        display: block !important;
    }

    &--flex {
        display: none !important;

        @include breakpoint(medium) {
            display: flex !important;
        }
    }
}

.hidden-medium {
    @include breakpoint(medium) {
        display: none !important;
    }
}

.visible-small {
    display: none !important;

    @include breakpoint(small) {
        display: block !important;
    }

    &--flex {
        display: none !important;

        @include breakpoint(small) {
            display: flex !important;
        }
    }
}

.hidden-small {
    @include breakpoint(small) {
        display: none !important;
    }
}

;@import "sass-embedded-legacy-load-done:12";