#privacy-choices-settings {
    h3,
    h4 {
        color: $color-white;
    }
}

#privacy-choices-prompt {
    transform: translateY(120%);
    h3 {
        color: $color-white;
    }
}

#privacy-choices-banner {
    > button {
        transform: translate(0.5rem, -0.5rem);
        padding: 0.5rem 1rem !important;
        min-height: unset !important;
        font-size: 0.8rem !important;
        background: $color-black !important;

        &:hover {
            background: lighten($color-black, 5%) !important;
            text-decoration: none !important;
        }

        @include breakpoint(large) {
            transform: translate(0.5rem, -0.5rem);
        }
    }
}

#privacy-choices #privacy-choices-banner #privacy-choices-prompt {
    box-shadow: none !important;
}

;@import "sass-embedded-legacy-load-done:45";