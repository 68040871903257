.more-services-section {
    .more-services-section--container {
        margin: 8rem auto;
        .more-services-section--title {
            margin-bottom: 1rem;
            h1 {
                color: $color-black;
                font-size: 5.5rem;
                margin: 0;
            }
        }

        .more-services-section--content {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            gap: 2rem;
            img {
                border-radius: 8px;
                object-fit: cover;
                width: 100%;
                aspect-ratio: 1 / 1;
                height: 280px;
                box-shadow: rgba(0, 0, 0, 0.2);
            }
            .service {
                display: flex;
                flex-direction: column;
                .service-title {
                    font-size: 32px;
                    line-height: 35.2px;
                    margin-top: 1rem;
                    text-align: center;
                    font-weight: 700;
                    max-width: 280px;
                }

                .image-paceholder {
                    background: grey;
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    height: 280px;
                    box-shadow: rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:47";