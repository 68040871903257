@mixin hidden {
    display: none !important;
}

@mixin visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0); /* for IE only */
    clip-path: inset(50%);
    white-space: nowrap;
    margin: 0 !important;
}

@mixin unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li::before {
        content: none;
    }
}

;@import "sass-embedded-legacy-load-done:10";