.section-block {
    position: relative;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    padding: 1.5rem;
    margin-left: -1rem;
    margin-right: -1rem;

    @include breakpoint(large) {
        padding: 2.5rem 0;
        margin-left: -2rem;
        margin-right: -2rem;
    }

    .container {
        display: flex;
        flex-flow: column;
        gap: 2.5rem;
        transition: all 0.5s ease;
        z-index: 1;

        .block {
            margin-left: auto;
            margin-right: auto;
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            @include breakpoint(large) {
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }
    }

    &.section-background {
        &-dark-green {
            background: $color-primary;
            color: $color-white;

            .button--colour {
                background-color: $color-white;
                color: $color-primary;

                .icon {
                    color: $color-primary;
                }

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-primary;
                }
            }
        }
        &-pink {
            background: $color-pink;
            color: $color-black;
        }
        &-yellow {
            background: $color-action;
            color: $color-black;
        }
        &-light-green {
            background: $color-secondary;
            color: $color-black;
        }
        &-light-violet {
            background: $color-tertiary-lighter;
            color: $color-black;
        }
        &-blue {
            background: $color-blue;
            color: $color-white;

            .button--colour {
                background-color: $color-white;
                color: $color-blue;

                .icon {
                    color: $color-blue;
                }

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-blue;
                }
            }
        }
        &-orange {
            background: $color-orange;
            color: $color-black;
        }
        &-violet {
            background: $color-tertiary;
            color: $color-white;

            .button--colour {
                background-color: $color-white;
                color: $color-tertiary;

                .icon {
                    color: $color-tertiary;
                }

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-tertiary;
                }
            }
        }
        &-red {
            background: $color-red;
            color: $color-white;

            .button--colour {
                background-color: $color-white;
                color: $color-red;

                .icon {
                    color: $color-red;
                }

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-red;
                }
            }
        }
        &-sky {
            background: $color-sky;
            color: $color-black;
        }
        &-white {
            background: $color-white;
            color: $color-black;
        }

        &-dark-green,
        &-blue,
        &-violet,
        &-red {
            a {
                color: $color-white;

                &:hover {
                    color: $color-light-grey;
                }
            }

            .button--outline {
                border: 2px solid $color-white;

                .icon {
                    color: $color-white;
                }
            }

            .button--text-only:hover {
                background: none;
                color: $color-light-grey;
            }

            .shape-tile__button {
                color: $color-black;

                &:hover {
                    color: $color-black;
                }
            }
        }
    }
}

.shape-divider-holder {
    position: relative;
}

.section-divider {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 0;

    mask-size: cover;
    mask-repeat: no-repeat;
    pointer-events: none;

    &.divider-top {
        top: -1px;

        &--pink {
            background-color: $color-pink !important;
        }
        &--yellow {
            background-color: $color-action !important;
        }

        &--light-green {
            background-color: $color-secondary !important;
        }

        &--dark-green {
            background-color: $color-primary !important;
        }

        &--light-violet {
            background-color: $color-tertiary-lighter !important;
        }

        &--blue {
            background-color: $color-blue !important;
        }

        &--orange {
            background-color: $color-orange !important;
        }

        &--violet {
            background-color: $color-tertiary !important;
        }

        &--red {
            background-color: $color-red !important;
        }

        &--sky {
            background-color: $color-sky !important;
        }

        &--white {
            background-color: $color-white !important;
        }
    }

    &.divider-bottom {
        bottom: -1px;

        &--pink {
            background-color: $color-pink !important;
        }
        &--yellow {
            background-color: $color-action !important;
        }

        &--light-green {
            background-color: $color-secondary !important;
        }

        &--dark-green {
            background-color: $color-primary !important;
        }

        &--light-violet {
            background-color: $color-tertiary-lighter !important;
        }

        &--blue {
            background-color: $color-blue !important;
        }

        &--orange {
            background-color: $color-orange !important;
        }

        &--violet {
            background-color: $color-tertiary !important;
        }

        &--red {
            background-color: $color-red !important;
        }

        &--sky {
            background-color: $color-sky !important;
        }

        &--white {
            background-color: $color-white !important;
        }
    }

    &.divider-swoosh {
        mask-image: url(../img/divider-swoosh.svg);
        background-size: contain;
        height: 48vw;

        &.divider-top {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-slanted-large {
        mask-image: url(../img/divider-slant-large.svg);
        background-size: contain;
        height: 10vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-slanted-small {
        mask-image: url(../img/divider-slant-small.svg);
        background-size: contain;
        height: 6.7vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-star {
        mask-image: url(../img/divider-star.svg);
        mask-size: cover;
        background-size: contain;
        height: 40vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-clouds {
        mask-image: url(../img/divider-clouds.svg);
        background-size: contain;
        height: 38vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-oval-small {
        mask-image: url(../img/divider-oval-small.svg);
        background-size: contain;
        height: 23vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-oval-large {
        mask-image: url(../img/divider-oval-large.svg);
        background-size: contain;
        height: 41vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }

    &.divider-rounded {
        mask-image: url(../img/divider-rounded.svg);
        background-size: contain;
        height: 13vw;

        &.divider-bottom {
            transform: scale(1, -1);
            &.flip-divider {
                transform: scale(-1, -1);
            }
        }

        &.flip-divider {
            transform: scale(-1, 1);
        }
    }
}

;@import "sass-embedded-legacy-load-done:61";