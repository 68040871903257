.portal-header {
    background-color: $color-primary;
    color: $color-white;
    padding: 0.75rem 1.25rem;

    display: grid;
    grid-template-columns: 1fr 1fr;

    div:first-child {
        display: flex;
        justify-content: flex-start;
    }

    div:last-child {
        display: flex;
        justify-content: flex-end;
    }

    a {
        color: $color-white;
        font-size: 1.25rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
            color: $color-secondary;
        }
    }

    .logo,
    .logo svg {
        height: 3rem;
        width: auto;
    }

    @include breakpoint(large) {
        grid-template-columns: 30% 40% 30%;
        padding: 1rem 2.5rem;

        a {
            font-size: 1.5rem;
        }

        .logo,
        .logo svg {
            height: 4rem;
        }

        div:nth-child(2) {
            display: flex;
            justify-content: center;
        }
    }
}

.portal-side-navigation {
    .button {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        line-height: 1rem;
        padding: 1.5rem 1rem;

        .icon {
            margin-right: 0 !important;
        }

        &.active {
            background-color: $color-secondary;
            color: $color-black;

            .icon {
                color: $color-black;
            }
        }
    }

    ul li {
        margin-bottom: 1rem;
    }
}

.portal-mobile-navigation {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: $color-white;
    display: flex;
    flex-flow: row nowrap;
    box-shadow: -2px 0px 12px -4px rgba(30, 37, 39, 0.5);

    ul {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: row nowrap;
        justify-content: center;
        gap: 1.25rem;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.75rem;
            text-align: center;
            gap: 0.25rem;

            &.active {
                border-bottom: 2px solid $color-secondary;
            }

            .icon {
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
}

.tenancy-portal-main {
    max-width: $width-container;
    margin: 3rem auto;
    padding: 0 1rem 5rem 1rem;

    @include breakpoint(large) {
        padding: 0 1rem;
        margin: 5rem auto;
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 4rem;
    }

    .portal-h1 {
        font-size: 4rem;
        margin-bottom: 1.5rem;
    }

    .side-navigation {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    .tenancy-form-container {
        max-width: 38.625rem;
        margin: 0 auto;

        form {
            margin-top: 0;
        }

        .section-block {
            margin-left: 0;
            margin-right: 0;

            .container {
                padding-left: 0;
                padding-right: 0;

                .block {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        > .section-block:first-of-type {
            padding-top: 0;
        }

        > .section-block:last-of-type {
            padding-bottom: 0;
        }
    }
}

.tenancy-dashboard-header {
    max-width: $width-container;
    margin: 3rem auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include breakpoint(large) {
        margin: 5rem auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.tenancy-balance {
    border: 4px solid $color-secondary;
    border-radius: 1rem;
    padding: 2.5rem;
    text-align: center;

    @include breakpoint(large) {
        padding: 3.75rem;
    }

    .tenancy-balance__title {
        font-family: $base-font;
        font-weight: 400;
        text-transform: none;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        line-height: 1;
    }

    .tenancy-balance__amount {
        font-size: 2rem;
        line-height: 1;
        font-weight: 700;
        margin: 1rem 0;

        @include breakpoint(large) {
            font-size: 4rem;
        }
    }

    .tenancy-balance__description {
        margin-top: 1rem;
        font-size: 1rem;
        color: $color-grey;

        @include breakpoint(large) {
            font-size: 1.125rem;
        }
    }
}

.tenancy-cards {
    margin-top: 2rem;
    border-top: 2px solid $color-light-grey;
    padding-top: 2rem;

    .tenancy-cards__title {
        display: flex;
        justify-content: space-between;

        h3 {
            font-family: $base-font;
            text-transform: none;
            font-size: 1.5rem;
        }

        a {
            font-size: 1.125rem;
        }
    }

    .tenancy-cards__grid {
        display: grid;
        gap: 2.5rem;

        @media (min-width: 1140px) {
            grid-template-columns: 1fr 1fr;
        }

        .card {
            width: 100%;
            border: 4px solid $color-secondary;
            border-radius: 1rem;
            padding: 2rem;
        }

        .card__meta {
            display: flex;
            justify-content: space-between;
            font-size: 1.125rem;
            margin: 0.5rem 0;
            gap: 1rem;

            span:first-child {
                font-weight: 700;
            }

            span:last-child {
                text-align: right;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:30";