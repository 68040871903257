.cta-block {
    display: flex;
    flex-flow: column;
    color: $color-white;
    background-color: $color-mental-health;
    border-radius: 0.5rem;
    min-height: 20rem;
    margin-top: 20vw;

    @include breakpoint(large) {
        flex-flow: row wrap;
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    h3 {
        font-size: clamp(3rem, 4vw, 4rem);
        margin: 0;
    }
    p {
        margin: 0;
        font-size: clamp(1rem, 2vw, 1.5rem);
    }

    .cta-block__content {
        display: flex;
        flex-flow: column wrap;
        gap: 1.5rem;
        padding: 2.5rem;
        flex: 50%;
        justify-content: center;

        @include breakpoint(large) {
            padding: 4rem 3rem;
        }
    }

    .cta-block__image {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        mask-image: url(../img/cloud.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        flex: 50%;
        aspect-ratio: 4/3;
        transform-origin: center bottom;
        transform: scale(1.3);
        flex: 100%;

        @include breakpoint(large) {
            transform-origin: center right;
            transform: scale(1.4);
            flex: 50%;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .cta-block__text {
        display: block;

        @include breakpoint(medium) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        @include breakpoint(large) {
            -webkit-line-clamp: 2;
            max-height: 6em;
        }
    }
    .button {
        margin: 0;
    }
}

.cta-block-shape-blue_cloud {
    color: $color-white;
    background-color: $color-mental-health;
    margin-bottom: 8rem;

    .cta-block__image {
        mask-image: url(../img/cloud.svg);
    }
    .button--colour {
        background-color: $color-white !important;
        color: $color-blue !important;
        .icon {
            color: $color-blue !important;
        }
        &:hover {
            background-color: darken($color-white, 10%) !important;
        }
    }
    .button--outline {
        background: none !important;
        border: 2px solid $color-white !important;
        color: $color-white !important;

        .icon {
            color: $color-white !important;
        }
        &:hover {
            border: 2px solid darken($color-white, 15%) !important;
            color: darken($color-white, 15%) !important;
        }
    }
    .button--text-only {
        color: $color-white;
        &:hover {
            background: #103e2066;
        }
    }
}

.cta-block-shape-orange_star {
    color: $color-black;
    background-color: $color-dementia;
    flex-direction: column-reverse;
    margin-top: unset;
    margin-bottom: 25vw;

    @include breakpoint(large) {
        margin-top: inherit;
        margin-bottom: inherit;
        flex-direction: row-reverse;
    }

    .cta-block__image {
        mask-image: url(../img/star.svg);
        transform-origin: center top;

        @include breakpoint(large) {
            transform-origin: center left;
        }
    }
    .button--colour {
        background-color: $color-black !important;
        color: $color-white !important;
        .icon {
            color: $color-white !important;
        }
        &:hover {
            background-color: lighten($color-black, 15%) !important;
        }
    }
    .button--outline {
        background: none !important;
        border: 2px solid $color-black !important;
        color: $color-black !important;

        .icon {
            color: $color-black !important;
        }
        &:hover {
            border: 2px solid lighten($color-black, 15%) !important;
            color: lighten($color-black, 15%) !important;

            .icon {
                color: lighten($color-black, 15%) !important;
            }
        }
    }
    .button--text-only {
        color: $color-black;
    }
}

.cta-block-shape-green_box {
    background-color: #9fca79;
    color: $color-primary;
    text-align: center;

    h3 {
        color: $color-black;
    }

    .cta-block__image {
        display: none;
    }
    .button--colour {
        background-color: $color-black !important;
        color: $color-white !important;
        .icon {
            color: $color-white !important;
        }
        &:hover {
            background-color: lighten($color-black, 15%) !important;
        }
    }
    .button--outline {
        background: none !important;
        border: 2px solid $color-black !important;
        color: $color-black !important;

        .icon {
            color: $color-black !important;
        }
        &:hover {
            border: 2px solid lighten($color-black, 20%) !important;
            color: lighten($color-black, 20%) !important;

            .icon {
                color: lighten($color-black, 20%) !important;
            }
        }
    }
    .button--text-only {
        color: $color-black;
    }
}

;@import "sass-embedded-legacy-load-done:57";