.banner {
    background: $color-light-grey;
    // nth-child(2) as there is a hidden h1 on standard pages
    &:not(.home-page_banner):nth-child(2) {
        margin-top: -3rem;
    }

    &:not(.home-page__banner) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .container--with-sidebar & {
        &:not(.home-page__banner) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .banner__container {
        max-width: $width-block-wide;
        width: 100%;
        margin: 2rem auto;
        gap: 2rem;
        padding: 1rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        @include breakpoint(large) {
            flex-flow: row wrap;
        }
        @include breakpoint(enormous) {
            padding: 0;
        }
    }
    .banner-content {
        order: 2;
        @include breakpoint(large) {
            width: calc(50% - 1rem);
            order: unset;
            max-width: 38.75rem;
        }
        .content {
            text-align: left;
            color: $color-black;
            margin-top: 0;
            font-size: 1.5rem;
        }
        &__buttons {
            gap: 1rem;
            .button {
                margin: 0;
            }
        }
    }
    img {
        max-height: 22.5rem;
        width: 100%;
        object-fit: cover;
        @include breakpoint(large) {
            max-width: calc(50% - 1rem);
            width: 44.25rem;
            height: 44.25rem;
            max-height: unset;
        }
    }
    h1 {
        text-align: left;
    }
    h2 {
        text-align: left;
    }
}

.image-background-banner {
    position: relative;
    min-height: 30rem;

    .header,
    .banner-content {
        position: relative;
    }

    .header {
        max-width: calc(100% - 4rem);
        margin-left: 0.5rem;
        color: $color-white;
        @include breakpoint(small) {
            max-width: calc(100% - 2rem);
        }
        @include breakpoint(medium) {
            max-width: 55rem;
        }
    }
    .sub-header {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
        max-width: 34rem;
        margin: 0;
        text-transform: none;
        font-size: 1.5rem;
        color: $color-white;
        @include breakpoint(medium) {
            padding: 0.5rem;
        }
    }

    .banner-content {
        padding-top: 0;
        text-align: center;
        @include breakpoint(medium) {
            padding-top: 2rem;
        }
        @include breakpoint(large) {
            p {
                font-size: 1.5rem;
            }
        }
        .content {
            p {
                font-size: 1rem;
                color: $color-white;
            }
        }
    }

    .banner-content__header {
        padding: 0 3rem 2rem 3rem;
        width: calc(100% + 4rem);
        z-index: 1;
        h1.header {
            background: none;
        }

        @include breakpoint(medium) {
            min-height: auto;
            width: auto;
        }
    }

    .banner-content__buttons {
        z-index: 1;
        margin-top: 0;
        @include breakpoint(medium) {
            margin-top: unset;
            .button {
                font-size: 1.375rem;
                padding: 1rem;
            }
        }
    }
    .image-block__credit {
        top: 0;
        bottom: auto;
    }

    &--block {
        margin-left: -$space-gap;
        margin-right: -$space-gap;

        &:first-child {
            margin-top: -$space-gap;
        }
    }
    .image-block__credit {
        top: 0;
        bottom: unset;
        right: 0;
    }
    .banner-content {
        padding-bottom: 0;
        @include breakpoint(medium) {
            padding-bottom: $space-gap * 5;
        }

        &.text-align-center {
            .rich-text {
                > * {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:42";