.resource-list {
    display: flex;
    flex-direction: column;
    padding: $space-gap;
    gap: 2rem;

    @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
    }

    .resource-list__content {
        flex: 1;
        margin-bottom: $space-gap;

        h2 {
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.2rem;
            margin-bottom: 1rem;

            @include breakpoint(large) {
                font-size: 5.5rem;
                line-height: 4rem;
            }
        }

        .resource-list__text {
            font-size: 1rem;
            line-height: 24px;
            margin-bottom: 1rem;

            @include breakpoint(large) {
                font-size: 1.5rem;
                line-height: 26px;
            }
        }
    }

    .resource-list__items {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .resource-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-top: 2px solid #dddddd;

            &:last-child {
                border-bottom: 2px solid #dddddd;

                .resource-item__type {
                    margin-bottom: 1.5rem;
                }
            }

            .resource-item__title {
                font-weight: 700;
                font-size: 2rem;
                text-decoration: none;
                transition: color 0.2s ease-in-out;

                &:hover {
                    color: #0073e6;
                }
            }

            .resource-item__type {
                font-size: 18px;
                text-transform: lowercase;
                margin-top: 0;
            }
        }
    }

    @include breakpoint(medium) {
        .resource-list__items {
            width: 100%;
        }

        .resource-list__content {
            width: 100%;
        }
    }

    @include breakpoint(large) {
        .resource-list__items {
            width: 48%;
            margin-left: 2rem;
        }

        .resource-list__content {
            width: 48%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:62";