.shape-tile {
    &--slanted-rectangle {
        background: url(../img/slanted-rectangle.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 5rem 1.5rem;

        display: flex;
        align-items: center;
    }
    &--oval {
        position: relative;
        background: url(../img/oval.svg);
        background-size: cover;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 5rem 2rem;

        display: flex;
        align-items: center;
        .shape-tile__content,
        .shape-tile__summary p {
            text-align: center;
        }
    }
    &--cloud {
        position: relative;
        background: url(../img/cloud.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding: 3rem;
        @include breakpoint(large) {
            padding: 6rem 3rem;
        }

        .shape-tile__content,
        .shape-tile__summary p {
            text-align: center;
        }
    }
}

.shape-tile__button {
    margin: 0;
    border-radius: 8px;
    padding-top: 14px;
    padding-right: 16px;
    padding-bottom: 14px;
    padding-left: 16px;
    background: $color-white;
    color: $color-black;
    font-size: 1.25rem;
    line-height: 0.8;
    @include breakpoint(large) {
        font-size: 24px;
        padding-top: 16px;
        padding-right: 20px;
        padding-bottom: 16px;
        padding-left: 20px;
    }
    &:hover {
        background: $color-light-grey;

        color: $color-black;
    }
}

.shape-tile__content {
    color: $color-black;
    h3 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.1;
        font-family: $base-font;
        text-transform: unset;
        @include breakpoint(large) {
            font-size: 2rem;
        }
    }

    p {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.4;
        text-align: left;
        @include breakpoint(large) {
            font-size: 1.5rem;
        }
    }
}
.shape-tile-block .grid {
    grid-gap: 3rem;
}

;@import "sass-embedded-legacy-load-done:60";