.auto-top-three {
    .grid {
        grid-gap: 1.5rem;
        justify-items: center;
    }
    h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 43.2px;
        word-break: break-word;

        @include breakpoint(large) {
            font-size: 5.5rem;
            line-height: 70.4px;
        }
    }

    .column {
        > img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
    .auto-top-three__content {
        padding: $space-gap;
        text-align: center;
        .h5 {
            font-size: 32px;
            line-height: 35.2px;
            letter-spacing: 0%;
            text-align: center;
        }
    }
    p {
        margin: calc($space-gap/2) 0;
    }
    &__image-container {
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.33));
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;
        flex-flow: column wrap;
        img {
            width: 100%;
        }
        .auto-top-three__content {
            padding-top: 1.5rem;
        }
        &:nth-of-type(3n + 1) {
            img {
                mask-image: url(../img/oval-wide.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
                width: 100%;
                object-fit: cover;
            }
            @include breakpoint(enormous) {
                margin-left: -2rem;
                margin-right: 2rem;
            }
        }
        &:nth-of-type(3n + 2) {
            img {
                mask-image: url(../img/speech.svg);
                mask-repeat: no-repeat;
                mask-position: bottom center;
                mask-size: contain;
                width: 100%;
                object-fit: cover;
            }
        }
        &:nth-of-type(3n + 3) {
            img {
                mask-image: url(../img/cloud.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
                width: 100%;
                object-fit: cover;
            }
            @include breakpoint(enormous) {
                margin-right: -2rem;
                margin-left: 2rem;
            }
        }
    }
    .container--with-sidebar & {
        &--two {
            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &__item {
            @include breakpoint(medium) {
                img {
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:55";