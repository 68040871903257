// Colors

$color-primary: #103e20 !default;
$color-primary-lighter: lighten($color-primary, 10%) !default;
$color-primary-darker: #092212 !default;
$color-secondary: #9fca79 !default;
$color-secondary-lighter: lighten($color-secondary, 10%) !default;
$color-secondary-darker: darken($color-secondary, 10%) !default;
$color-tertiary: #4a2778;
$color-tertiary-lighter: #bca3ce;
$color-tertiary-darker: darken($color-tertiary, 10%) !default;
$color-action: #ffcc3b !default;
$color-action-lighter: lighten($color-action, 10%) !default;
$color-action-darker: darken($color-action, 10%) !default;
$color-pink: #ed6ca5 !default;
$color-pink-lighter: lighten($color-action, 10%) !default;
$color-pink-darker: darken($color-action, 10%) !default;
$color-mental-health: #283583;
$color-environment: #9fca79;
$color-supported_accomodation: #7ca1d5;
$color-dementia: #ed694b;

$color-black: #000000 !default;
$color-white: #ffffff !default;
$color-grey: #4e4e4e !default;
$color-blue: #283583 !default;
$color-orange: #ed694b !default;
$color-red: #e50045 !default;
$color-sky: #7ca1d5 !default;
$color-medium-grey: #999999 !default;
$color-light-grey: #e9e9e9 !default;

$color-insert: #258750 !default;
$color-delete: $color-action !default;
$color-footer: #333333 !default;
$color-background: #333333 !default;
$color-line: #c0c0c0 !default;
$color-label: $color-black !default;

;@import "sass-embedded-legacy-load-done:8";