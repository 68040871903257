.right-sidebar__content {
    max-width: $width-text;
    margin: 0 auto;
    padding: 2rem 5.5rem 2rem 2.5rem;
    @include breakpoint(large) {
        max-width: 15rem;
    }
    h3 {
        font-size: 1.188rem;
        text-transform: none;
        line-height: 1.2;
    }
    .button--action {
        margin: 0.5rem 0;
        padding: 0.5rem;
        font-size: 0.9375rem;
        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
.meta {
    .button--light {
        margin: 0.5rem 1rem 0 0 !important;
    }
}
.news-meta {
    margin-left: auto;
    margin-right: auto;
    hr {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.event-date {
    text-align: right;
    font-size: 1.125rem;
}

.detail-back-div {
    margin-top: 2rem !important;
    a {
        font-size: 20px;
        padding-top: 14px;
        padding-right: 16px;
        padding-bottom: 14px;
        padding-left: 16px;
    }

    .icon {
        margin-left: 0 !important;
        height: 14px !important;
        width: 14px !important;
        color: $color-secondary;
        fill: $color-secondary;
    }
}

.service-type {
    padding: 4px 5px;
    border-radius: 4px;
    color: $color-white;
    font-size: 18px;
    font-weight: 700;
}

;@import "sass-embedded-legacy-load-done:25";