.repairs-intro {
    display: grid;
    gap: 2.5rem;
    margin: 2rem 0;

    @include breakpoint(large) {
        grid-template-columns: 1fr 1fr;
    }

    > div {
        border-radius: 1rem;
        padding: 2rem;
        font-size: 1.125rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .button {
        margin: 2rem 0 0 0;
        width: fit-content;
    }

    .phone-number {
        display: block;
        font-size: 3rem;
    }

    .primary {
        background-color: $color-primary;
        color: $color-white;

        .button--outline {
            background: none !important;
            border: 2px solid $color-white !important;
            color: $color-white !important;

            .icon {
                color: $color-white !important;
            }
        }
    }

    .secondary {
        background-color: $color-secondary;
    }
}

.repairs-list {
    margin-top: 4rem;
    border-top: 2px solid $color-light-grey;
    padding-top: 4rem;

    h2 {
        font-family: $base-font;
        text-transform: none;
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 1.5rem;
        line-height: 1.4;
    }
}

.repairs-concertina {
    margin-bottom: 1.5rem;

    summary {
        padding: 1.5rem;

        p {
            font-size: 1.125rem;
            font-weight: 400;
            margin-top: 0.25rem;
        }
    }

    .open,
    .closed {
        .icon {
            width: 2rem;
            height: 2rem;
        }
    }

    .primary-bg {
        p {
            color: $color-secondary;
        }
    }

    .secondary-bg {
        h3 {
            color: $color-black;
        }

        p {
            color: $color-primary;
        }

        + .rich-text {
            border-color: $color-secondary;
        }
    }

    h4 {
        font-family: $base-font;
        text-transform: none;
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    .meta-row {
        p {
            margin-top: 0;

            .font-bold {
                display: block;
            }
        }

        p:last-child {
            text-align: right;
        }
    }
}

[open] .primary-bg {
    background: $color-primary;
}

[open] .secondary-bg {
    background: $color-secondary;
}

.status-step {
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    gap: 1.5rem;

    p {
        margin-top: 0;
    }

    .font-bold {
        display: block;
        margin: -0.25rem 0 0.25rem 0;
    }

    &.status-step--complete {
        background-color: $color-primary;
        color: $color-white;
    }

    &.status-step--current {
        background-color: $color-secondary;
    }

    &.status-step--future {
        background-color: #e7ece9;
    }

    .status-step__number {
        font-weight: 700;
        font-family: 'Artz';
        text-transform: uppercase;
        font-size: 3rem;
        line-height: 0.9;
        display: block;
    }
}

;@import "sass-embedded-legacy-load-done:31";