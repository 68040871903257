table {
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    th,
    td {
        border: 1px solid;
        padding: 0.5rem;
        text-align: left;
    }
    caption {
        margin-bottom: 1rem;
    }
    th {
        background: $color-white;
        color: $color-black;
    }
}
.block-table {
    overflow: auto;
}

;@import "sass-embedded-legacy-load-done:46";