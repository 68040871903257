.tile-block {
    &__title {
        text-align: center;
        font-size: 2.5rem;

        @include breakpoint(large) {
            font-size: 5rem;
        }
    }
}

.tile-grid {
    &--two {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.tile--horizontal {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    .tile__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1em;
        color: inherit;
        text-decoration: none;

        @include breakpoint(large) {
            flex-direction: row;
            gap: 2.5rem;
        }
    }

    .tile__image {
        aspect-ratio: 1/1;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.33));

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
        }
    }

    .tile__content {
        padding: 1rem 0;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        justify-content: center;

        h3 {
            font-size: 2rem;
            text-transform: unset;
            margin: 0;
            font-family: $base-font;
            @include breakpoint(large) {
                font-size: clamp(1.125rem, 2.4vw, 2rem);
            }
        }
        p,
        div {
            margin: 0;
        }

        .tile__summary {
            font-size: 1.5rem;

            @include breakpoint(large) {
                font-size: clamp(1.125rem, 1.4vw, 1.5rem);
            }
        }
    }

    @include breakpoint(large) {
        .tile__image,
        .tile__content {
            flex: 1 1 50%;
            max-width: 50%;
        }
    }
}

.tile__summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include breakpoint(medium) {
        -webkit-line-clamp: 4;
    }
}

;@import "sass-embedded-legacy-load-done:50";