.quote-block {
    position: relative;
    margin-bottom: 10rem;
    margin-top: 3rem !important;

    @include breakpoint(medium) {
        margin-top: 5rem !important;
    }

    @include breakpoint(large) {
        max-width: 52rem;
        width: unset;
        margin-top: 7rem !important;
    }

    &__content {
        position: relative;
        font-family: 'Artz';
        font-weight: 700;
        font-size: 36px;
        line-height: 0.8;
        margin-right: 2rem;
        @include breakpoint(medium) {
            margin-right: 2rem;
        }

        @include breakpoint(large) {
            font-weight: 700;
            font-size: 64px;
            line-height: 57.6px;
            margin-right: 5rem;
        }

        q {
            padding: 0 3.5rem 0;
            color: $color-action;
            margin: auto;
            display: flex;
            align-items: center;
            background-color: $color-tertiary;
            position: relative;
            line-height: 0.9;
            @include breakpoint(large) {
                max-width: none;
                line-height: 0.8;
            }
            &:before {
                content: '';
                background-image: url(../img/speech-bubble-top-new.svg);
                position: absolute;
                display: block;
                height: 5rem;
                width: 100%;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: bottom center;
                z-index: -1;
                left: 0;
                right: 0;
                bottom: 99%;
                @include breakpoint(medium) {
                    height: 8rem;
                }

                @include breakpoint(large) {
                    height: 8rem;
                    width: 100%;
                    left: -0;
                    right: -0;
                }
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 18rem;
                width: 100%;
                background-image: url(../img/speech-bubble-bottom-new.svg);
                background-size: 100%;
                background-repeat: no-repeat;
                bottom: -17.9rem;
                left: 0;
                right: 0;
                z-index: -1;
            }
        }
        .h4 {
            text-transform: capitalize;
        }
    }
    cite {
        text-align: left;
        color: $color-black;
        font-family: $base-font;
        font-weight: 700;
        text-align: right;
        font-size: 16px;
        line-height: 19.2px;
        position: relative;
        margin-top: 22%;
        margin-right: -2rem;
        padding-right: 2rem;

        @include breakpoint(medium) {
            margin-top: 20%;
        }

        @include breakpoint(large) {
            margin-top: 10rem;
            font-size: 24px;
            line-height: 28.8px;
            padding-right: 1rem;
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 13rem;
            width: 100%;
            background-image: url(../img/quote-bubble-2.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            bottom: -4rem;
            left: 3rem;
            z-index: -2;
            @include breakpoint(small) {
                height: 16rem;
                bottom: -5rem;
            }
            @media (min-width: 512px) {
                left: 16%;
            }
            @include breakpoint(medium) {
                height: 26rem;
                bottom: -8rem;
                left: unset;
                right: -6%;
            }
            @media (min-width: 800px) {
                right: -11%;
            }
            @include breakpoint(large) {
                height: 33rem;
                bottom: -10rem;
                right: -12%;
                background-image: url(../img/quote-bubble-2.svg);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:53";