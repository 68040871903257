.footer--logo {
    margin-top: 1rem;
    &__image {
        display: block;
        max-width: 13rem;
        max-height: 7.5rem;
        width: auto;
        height: auto;
    }
    svg {
        width: 132px;
    }
}

.footer__navigation .block-menu {
    display: flex;
    flex-direction: column;
    margin: 0;

    h5 {
        a {
            color: $color-black;
            font-size: 1.2rem;
            margin: 0 1rem;
        }
    }

    .footer-quicklinks__menu {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;

        a {
            margin: 0 auto 0.3rem 0;
            padding: 0.5rem 1rem;
        }
        a.button {
            margin: 0 auto 0.3rem 2.2rem;
        }

        .button_link {
            padding: 0.3125rem 0.75rem;
            border-radius: 0.25rem;
            font-size: 0.9375rem;
        }
    }
}

.footer-legal {
    .rich-text,
    p {
        max-width: 100%;
        margin: 0 1rem 0 0.5rem;
    }
}

.footer-bottom {
    img {
        object-fit: contain;
    }
}

.footer__top {
    margin: 0 1rem;
    hr {
        background: rgba(159, 202, 121, 1);
        margin: 1rem 0;
        height: 1px;
        max-width: 100%;
    }
    .footer--logo {
        display: flex;
        justify-content: center;
    }
}

.footer-end {
    background: $color-primary-darker;
    margin: 0;
    padding: 1rem 2rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    @include breakpoint(medium) {
        flex-direction: row;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        a {
            color: $color-white;
            text-decoration: none;
        }
    }
}

.footer {
    z-index: 1; // Position above section dividers
    position: relative;

    .cta-block__background-image {
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }
}
.footer-navigation--container {
    nav {
        h4 {
            margin-bottom: 0;
        }
        h4 a {
            color: $color-white;
            font-size: 21px;
            font-family: $base-font;
            font-weight: 700;
            line-height: 33.6px;
            text-transform: capitalize;
            white-space: nowrap;
        }
        ul {
            display: flex;
            color: $color-white;
            justify-content: flex-start;
            flex-flow: column nowrap;
            gap: 0.5rem;
            margin: 0 auto 3rem auto;
            padding-left: 0;
            @include breakpoint(medium) {
                justify-content: center;
                gap: 1rem 2rem;
                flex-flow: row wrap;
            }

            li a {
                color: $color-white;
                text-transform: capitalize;
                font-size: 1rem;
                font-weight: 700;

                &:hover {
                    text-decoration: underline;
                }
            }
            li::before {
                display: none;
            }
        }
    }
}
.footer-copyright {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    p {
        max-width: 553px;
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        @include breakpoint(large) {
            font-size: 18px;
            line-height: 1.8;
        }
        a {
            color: $color-white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-social {
        display: flex;
        gap: 1rem;
        height: 32px;

        p {
            font-size: 18px;
            font-weight: 700;
            margin: 0;
        }
        ul.social {
            margin: 0;
            gap: 1rem;

            li {
                margin: 0;
                padding: 0;
                height: 32px;
                a {
                    margin: 0;
                    padding: 0;
                    width: unset;
                    height: unset;
                    .icon {
                        width: 32px;
                        height: 32px;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:18";