.service-type--resettlement {
    background-color: $color-tertiary !important;
    color: $color-white !important;
}

.service-type--mental_health {
    background-color: $color-blue !important;
    color: $color-white !important;
}

.service-type--domestic_abuse {
    background-color: $color-secondary !important;
    color: $color-black !important;
}

.service-type--young_people {
    background-color: $color-pink !important;
    color: $color-black !important;
}

.service-type--supported_accomodation {
    background-color: $color-sky !important;
    color: $color-black !important;
}

.service-type--well_being {
    background-color: $color-tertiary-lighter !important;
    color: $color-black !important;
}

.service-type--dementia_support {
    background-color: $color-action !important;
    color: $color-black !important;
}

.service-type--inspire_north {
    background-color: $color-primary !important;
    color: $color-white !important;
}

/* Apply service-specific colors only to blocks that already have a background color, EXCLUDING white */
[class^='page-service-type--']
    .section-block[class*='section-background']:not(.section-background-white),
[class^='page-service-type--']
    .block[class*='background']:not(.section-background-white),
[class^='page-service-type--']
    .resource-page-banner:not(.section-background-white) {
    background-color: inherit !important;
    color: inherit !important;
}

// Each Rule info/docs: https://www.geeksforgeeks.org/sass-each-rule/
/* Apply correct service-specific background colors (excluding white) */
@each $service, $color,
    $textColor
        in (
            resettlement $color-tertiary $color-white,
            mental_health $color-blue $color-white,
            domestic_abuse $color-secondary $color-black,
            young_people $color-pink $color-black,
            supported_accomodation $color-sky $color-black,
            well_being $color-tertiary-lighter $color-black,
            dementia_support $color-action $color-black,
            inspire_north $color-primary $color-white
        )
{
    .page-service-type--#{$service}
        .section-block[class*='section-background']:not(
            .section-background-white
        ),
    .page-service-type--#{$service}
        .block[class*='background']:not(.section-background-white),
    .page-service-type--#{$service}
        .resource-page-banner:not(.section-background-white) {
        background-color: $color !important;
        color: $textColor !important;
    }
}

/* Apply service-specific background colors to CTA and Concertina blocks */
@each $service, $color,
    $textColor
        in (
            resettlement $color-tertiary $color-white,
            mental_health $color-blue $color-white,
            domestic_abuse $color-secondary $color-black,
            young_people $color-pink $color-black,
            supported_accomodation $color-sky $color-black,
            well_being $color-tertiary-lighter $color-black,
            dementia_support $color-action $color-black,
            inspire_north $color-primary $color-white
        )
{
    .page-service-type--#{$service} .cta-block,
    .page-service-type--#{$service} summary {
        background-color: $color !important;
        color: $textColor !important;

        .cta-block__text a {
            color: $textColor !important;
        }
    }
    .page-service-type--#{$service} details .rich-text {
        border: 2px solid $color !important;
    }
}

// Override divider colors for each service type
@each $service,
    $color
        in (
            resettlement $color-tertiary,
            mental_health $color-blue,
            domestic_abuse $color-secondary,
            young_people $color-pink,
            supported_accomodation $color-sky,
            well_being $color-tertiary-lighter,
            dementia_support $color-action,
            inspire_north $color-primary
        )
{
    .page-service-type--#{$service} .section-divider.divider-top {
        background-color: $color !important;
    }

    .page-service-type--#{$service} .section-divider.divider-bottom {
        background-color: $color !important;
    }
    .page-service-type--#{$service} .standard-page-banner {
        &:after {
            background-color: $color !important;
        }
    }
}
.resource-page-banner {
    margin-left: -2rem;
    margin-right: -2rem;
}
.resource-page-banner .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .newsarticlepage {
        background: $color-secondary;
        margin-top: -1rem;
        padding-top: 1rem;
        hr {
            background: #718f56;
        }
    }
}

;@import "sass-embedded-legacy-load-done:28";